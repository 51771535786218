import flagus from "../images/flags/us.svg";
import flagspain from "../images/flags/spain.svg";


const languages = {
  es: {
    label: "Español",
    flag: flagspain,
  },
  en: {
    label: "English",
    flag: flagus,
  },
}

export default languages
