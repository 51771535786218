import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Card, CardHeader, CardBody, Spinner, Table } from "reactstrap";
import { Link, useParams } from 'react-router-dom';
import getChartColorsArray from "../../Components/Commons/ChartsDynamicColor";
import Loader from "../../Components/Commons/Loader";
import OrderImportStatus from "./OrderImportStatus";
import ImportChart from "./ImportChart"

import { useGetOrderImportByIdQuery } from '../../api/api'

import * as moment from "moment";

const OrdersImportSummary = ({t, i18n}) => {

	let { reference } = useParams();

	const [skipPolling, setSkipPolling] = useState(false);

	const { 
		data: ordersImport,
		error,
		isFetching: isOrdersImportLoading,
		isSuccess: isOrdersImportSuccess
	} = useGetOrderImportByIdQuery(
		reference,
		{
			pollingInterval: skipPolling ? 0 : 5000,
			refetchOnMountOrArgChange: true,
		}
	);

	useEffect(() => {
		if (ordersImport) {
			setSkipPolling(ordersImport.status !== 3);
		}
	}, [ordersImport]);

	const handleValidDate = date => {
		const date1 = moment(date).format("DD/MM");
		return date1;
	};

	const handleValidTime = (time) => {
		const updateTime = moment(time).format('HH:mm');
		return updateTime;
	};

	const handlePercentage = (partialValue, totalValue) => {
		if (totalValue > 0){
			const value = Math.round((partialValue / totalValue) * 100);
			return value;
		} else{
			return 0;
		}
	};

	return (
		<React.Fragment>
			<Card className="mb-3">
				<CardHeader className="align-items-center d-flex">
					<h4 className="card-title mb-0 flex-grow-1">{t("Orders Import Details")}</h4>
				</CardHeader>
				<CardBody>
				<div className="text-center mb-4">
					{isOrdersImportLoading && <Loader/>}
					{isOrdersImportSuccess && (
						<div className="text-center">
							<h2 className="overview-companyname">
								{ordersImport.reference}
							</h2>
							<p className="text-muted m-0">{t("Orders Import Reference")}</p>
						</div>
					)}
				</div>
					{isOrdersImportLoading && <Loader/>}
					{isOrdersImportSuccess && (
						<div className="table-responsive table-card">
							<Table className="table mb-0">
								<tbody>
									<tr>
										<td className="fw-medium">{t("Company")}</td>
										<td>{ordersImport.company.name}</td>
									</tr>
									<tr>
										<td className="fw-medium">{t("Created at")}</td>
										<td>{handleValidDate(ordersImport.created_at)} {handleValidTime(ordersImport.created_at)}</td>
									</tr>
									<tr>
										<td className="fw-medium">{t("Billing day")}</td>
										<td>{handleValidDate(ordersImport.billing_date)} {handleValidTime(ordersImport.billing_date)}</td>
									</tr>
									<tr>
										<td className="fw-medium">{t("Status")}</td>
										<td><OrderImportStatus status={ordersImport.status} status_text={ordersImport.status_text} /></td>
									</tr>
								</tbody>
							
							</Table>
						</div>
					)}
				</CardBody>
			</Card>
			<Card className="mb-3">
				<CardHeader className="align-items-center d-flex">
					<h4 className="card-title mb-0 flex-grow-1">{t("Progress")}</h4>
				</CardHeader>
				<CardBody>
					{isOrdersImportLoading && <Loader/>}
					{isOrdersImportSuccess && (
						<div className="d-flex align-items-center">
							<div className="flex-grow-1 overflow-hidden">
								<p className="text-uppercase fw-medium text-muted text-truncate mb-3">
									{t("File rows")}
								</p>
								{ordersImport.status == 4 && (
									<h4 className="fs-22 fw-semibold ff-secondary mb-0">
										<span className="counter-value" data-target="36894">
											{ordersImport.ready_count}/{ordersImport.row_count}
										</span>
									</h4>
								)}
								{ordersImport.status == 3 && (
									<h4 className="fs-22 fw-semibold ff-secondary mb-0">
										<span className="counter-value" data-target="36894">
											{t("Importing...")}
										</span>
									</h4>
								)}
							</div>
							<div className="flex-shrink-0">
								{ordersImport.status == 4 && (
									<ImportChart
										seriesData={handlePercentage(ordersImport.ready_count, ordersImport.row_count) }
										colors={getChartColorsArray('["--vz-success"]')[0]}
									/>
								)}
								{ordersImport.status == 3 && (
									<Spinner size="m" className='me-2'> </Spinner>
								)}
								
							</div>
						</div>
					)}
				</CardBody>
			</Card>
			<div className="card mb-3">
				<div className="card-body">
					<div className="d-flex mb-3">
						<h6 className="card-title mb-0 flex-grow-1">{t("Created By")}</h6>
					</div>
					{isOrdersImportLoading && <Loader/>}
					{isOrdersImportSuccess && (
						<ul className="list-unstyled vstack gap-3 mb-0">
							<li>
								<div className="d-flex align-items-center">
									<div className="flex-shrink-0">
									</div>
									<div className="flex-grow-1 ms-2">
										<h6 className="mb-1"><Link to={`../users/${ordersImport.creator.id}`} >{ordersImport.creator.first_name} {ordersImport.creator.last_name}</Link></h6>
										<p className="text-muted mb-0">{ordersImport.creator.email}</p>
									</div>
								</div>
							</li>
						</ul>
					)}
				</div>
			</div>
		</React.Fragment>
	);
};

export default withTranslation()(OrdersImportSummary);