import React, { useState, useEffect, useRef } from 'react';
import { CardBody, Row, Col, Card, Table, CardHeader, Container, Button } from 'reactstrap';
import Loader from "../../Components/Commons/Loader";
import BreadCrumb from "../../Components/Commons/BreadCrumb";
import { Link, useParams } from "react-router-dom";
import * as moment from "moment";
import { withTranslation, useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import { 
	useGetPickingByIdQuery
 } from '../../api/api'


 export const PrintableOutOfBox = React.forwardRef((props, ref) => {
	
	let { id } = useParams();

	const { t, i18n } = useTranslation();

	const { 
		data: picking,
		error,
		isFetching: isPickingLoading,
		isSuccess: isPickingSuccess
	} = useGetPickingByIdQuery(
		id,
		{
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

	const [totalAmount, setTotalAmount] = useState(0);

	const handleValidDate = date => {
		const date1 = moment(date).format("DD/MM");
		return date1;
	};

	const handleValidTime = (time) => {
		const updateTime = moment(time).format('HH:mm');
		return updateTime;
	};

	useEffect(() => {
		if (picking) {
			const sum = picking.out_of_boxes.map(
				item => item.quantity).reduce((a, b) => a + b);
			setTotalAmount(sum);
		}
	}, [picking]);

	return (
		<div ref={ref}>
			<Row className="justify-content-center">
				<Col xxl={9}>
					{isPickingLoading && <Loader/>}
					{isPickingSuccess && (
						<Card id="demo">
							<Row>
								<Col lg={12}>
									<CardHeader className="border-bottom-dashed p-4">
										<div className="d-flex">
											<div className="flex-grow-1">
												<div className="mt-sm-5 mt-4">
													<h2 className="text-muted text-uppercase fw-semibold">
														{t("Out Of Box Guide")}
													</h2>
												</div>
											</div>
											<div className="flex-shrink-0 mt-sm-0 mt-3">
												<h3>
													<span id="legal-register-no">PROGISTICA</span>
												</h3>
												<h6>
													<span id="email">info@transayala.cl</span>
												</h6>
												<h6>
													<Link to="#" className="link-primary" id="website">
														www.transayala.cl
													</Link>
												</h6>
												<h6 className="mb-0">
													<span id="contact-no"> +56 (2) 3262 6182</span>
												</h6>
											</div>
										</div>
									</CardHeader>
								</Col>
								<Col lg={12}>
									<CardBody className="p-4">
										<Row className="g-3">
											<Col lg={3} xs={6}>
												<p className="text-muted mb-2 text-uppercase fw-semibold">
													{t("Picking Id")}
												</p>
												<h5 className="fs-14 mb-0"><span id="invoice-no">{id}</span></h5>
											</Col>
											<Col lg={3} xs={6}>
												<p className="text-muted mb-2 text-uppercase fw-semibold">
													{t("Creation date")}
												</p>
												<h5 className="fs-14 mb-0">
													<span id="invoice-date">{handleValidDate(picking.created_at)}</span> <small className="text-muted" id="invoice-time">{handleValidTime(picking.created_at)}</small>
												</h5>
											</Col>
											<Col lg={3} xs={6}>
												<h6 className="text-muted text-uppercase fw-semibold mb-3">
													{t("Driver")}
												</h6>
												{picking.driver && picking.driver.user && (
													<>
														<p className="fw-medium mb-2" id="shipping-name">{picking.driver.user.first_name}</p>
														<p className="text-muted mb-1"><span>Rut: </span><span id="shipping-phone-no">{picking.driver.rut}</span></p>
													</>
												)}
												
											</Col>
										</Row>
									</CardBody>
								</Col>
								<Col lg={12}>
									<CardBody className="p-4">
										<div className="table-responsive">
											<Table className="table-borderless text-center table-nowrap align-middle mb-0">
												<thead>
													<tr className="table-active">
														<th scope="col">{t("Out of Box Details")}</th>
														<th scope="col">{t("Quantity")}</th>
													</tr>
												</thead>
												<tbody id="products-list">
													{picking.out_of_boxes && picking.out_of_boxes.map((item, key) => (
														<tr key={key} >
															<td className="text-start">
																<span className="fw-medium">
																	{item.afp_name}
																</span>
															</td>
															<td>{item.quantity}</td>
														</tr>
													))}
												</tbody>
											</Table>
										</div>
										<div className="border-top border-top-dashed mt-2">
											<Table className="table table-borderless table-nowrap align-middle mb-0 ms-auto" style={{ width: "250px" }}>
												<tbody>
													<tr className="border-top border-top-dashed fs-15">
														<th scope="row">{t("Total Amount")}</th>
														<th className="text-end">{totalAmount}</th>
														<th></th>
														<th></th>
														<th></th>
														<th></th>
														<th></th>
														<th></th>
													</tr>
												</tbody>
											</Table>
										</div>
									</CardBody>
								</Col>
							</Row>
						</Card>
					)}
				</Col>
			</Row >
		</div>
	);
});

document.title = "Sistema de pedidos PROGISTICA";

const OutOfBox = ({t, i18n}) => {
	
	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<div className="page-content">
			<Container fluid>
				<BreadCrumb title={t("Out of Box Details")} pageTitle={t("Pickings")} />
				<PrintableOutOfBox ref={componentRef}/>
				<Col lg={12}>
					<CardBody className="p-4">
						<div className="hstack gap-2 justify-content-end d-print-none mt-4">
							<Button onClick={handlePrint}
							>
								<i className="ri-printer-line align-bottom me-1"></i> {t("Print")}
							</Button>
						</div>
					</CardBody>
				</Col>
			</Container >
		</div >
	);
};

export default withTranslation()(OutOfBox);