import React, { useEffect, useState, useMemo, useCallback } from "react";
import { withTranslation } from 'react-i18next';
import {
	Input,
	Card,
	CardBody,
	Col,
	Container,
	CardHeader,
	ModalHeader,
	Modal,
	ModalBody,
	Row,
	Form,
	Label,
	FormFeedback
} from "reactstrap";
import * as moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { useAsyncDebounce } from "react-table";
import { useFormik } from "formik";
import Flatpickr from "react-flatpickr";
import { isEmpty } from "lodash";

import BreadCrumb from "../../Components/Commons/BreadCrumb";
import RoutesTable from "./RoutesTable";
import Pagination from "../../Components/Commons/Pagination";
import ConfirmModal from "../../Components/Commons/ConfirmModal";

import 'react-toastify/dist/ReactToastify.css';

import { 
	useGetPickingsQuery,
	useImportOrdersMutation 
} from '../../api/api'

const RoutesPage = ({ t, i18n }) => {

	let navigate = useNavigate();

	const [modal, setModal] = useState(false);

	const [currentPage, setCurrentPage] = useState(1);
	const [creationStartDate, setCreationStartDate] = useState(null);
	const [creationEndDate, setCreationEndDate] = useState(null);
	const [billingStartDate, setBillingStartDate] = useState(null);
	const [billingEndDate, setBillingEndDate] = useState(null);
	const [campaign, setCampaign] = useState(null);
	const [rut, setRut] = useState(null);

	const [createPickingModal, setCreatePickingModal] = useState(false);

	const { 
		data: pickingsPages,
		error,
		isFetching: pickingsLoading,
		isSuccess: pickingsSuccess
	} = useGetPickingsQuery(
		{ 
			page: currentPage,
			creationStartDate: creationStartDate,
			creationEndDate: creationEndDate,
			billingStartDate: billingStartDate,
			billingEndDate: billingEndDate,
			campaign: campaign,
			routes: true,
			driverRut: rut
		}, 
		{
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

	/*useEffect(() => {
		if (pickingsPages) {
			debugger;
		}
	}, [pickingsPages]);*/

	const toggle = useCallback(() => {
		if (modal) {
			setModal(false);
		} else {
			setModal(true);
		}
	}, [modal]);

	const handleCreateInputPicking = () => {

		const operational_block = {
			id: id
		};
		
		const newInputPicking = {
			operational_block: operational_block,
			kind: 0,
		};
		// create new user
		createPicking(newInputPicking);
	};

	document.title = "Sistema de pedidos PROGISTICA";

	return (
		<div className="page-content">
			<Container fluid>
				<BreadCrumb title={t("Routes List")} pageTitle={t("Routes")} />
				{/*<ConfirmModal
					show={createPickingModal}
					onConfirmClick={() => handleCreateInputPicking()}
					onCloseClick={() => setCreatePickingModal(false)}
					title="Create new entry picking"
					question="Do you want to create an Entry Picking linked to you?"
					yesQuestion="Yes, create it!"
				/>*/}
				<Row>
					<Col lg={12}>
						<Card id="pickingsList">
							<CardHeader className="border-0">
								<Row className="align-items-center gy-3">
									<div className="col-sm">
										<h5 className="card-title mb-0">{t("Routes")}</h5>
									</div>
									<div className="col-sm-auto">
										{/*<div>
											<button
												type="button"
												className="btn btn-success add-btn"
												id="create-btn"
												onClick={() => { toggle(); }}
											>
												<i className="ri-download-line align-bottom me-1"></i> Create input picking
											</button>
										</div>*/}
									</div>
								</Row>
							</CardHeader>
							<CardBody className="pt-0">
								<div>
									<Row className="mb-3">
										<CardBody className="border border-dashed border-end-0 border-start-0">
											<form>
												<Row>
													<React.Fragment>

														<Col md={3} className="col-xxl-3">
															<Flatpickr
																className="form-control"
																id="datepicker-created-at"
																placeholder={t("Created at")}
																options={{
																	altInput: true,
																	altFormat: "F j, Y",
																	mode: "range",
																	dateFormat: "DD-MM-YYYY",
																}}
																onChange={(date) => {
																	setCurrentPage(1);
																	if(date[0]!== undefined){
																		const date1 = moment(date[0]).format("DD-MM-YYYY");
																		setCreationStartDate(date1);
																	} else {
																		setCreationStartDate(null);
																	}
																	if(date[1]!== undefined){
																		const date1 = moment(date[1]).format("DD-MM-YYYY");
																		setCreationEndDate(date1);
																	} else {
																		setCreationEndDate(null);
																	}
																}}
															/>
														</Col>
														
														<Col sm={6} className="col-xxl-3">
															<div>
																<Flatpickr
																	className="form-control"
																	id="datepicker-billing-date"
																	placeholder={t("Billing Day")}
																	options={{
																		altInput: true,
																		altFormat: "F j, Y",
																		mode: "range",
																		dateFormat: "DD-MM-YYYY",
																	}}
																	onChange={(date) => {
																		setCurrentPage(1);
																		if(date[0]!== undefined){
																			const date1 = moment(date[0]).format("DD-MM-YYYY");
																			setBillingStartDate(date1);
																		} else {
																			setBillingStartDate(null);
																		}
																		if(date[1]!== undefined){
																			const date1 = moment(date[1]).format("DD-MM-YYYY");
																			setBillingEndDate(date1);
																		} else {
																			setBillingEndDate(null);
																		}
																	}}
																/>
															</div>
														</Col>

														<Col sm={6} className="col-xxl-3">
															<Input
																name="campaignId"
																id="id-field"
																className="form-control"
																placeholder={t("Campaign Id")}
																type="text"
																validate={{
																	required: { value: true },
																}}
																onChange={(e) => {
																	setCurrentPage(1);
																	setCampaign(e.target.value && e.target.value.length > 0 ? e.target.value : null);
																}}
															/>
														</Col>
											
														<Col sm={6} className="col-xxl-3">
															<Input
																name="driverRut"
																id="driverRut"
																className="form-control"
																placeholder={t("Driver RUT")}
																type="text"
																validate={{
																	required: { value: true },
																}}
																onChange={(e) => {
																	setCurrentPage(1);
																	setRut(e.target.value && e.target.value.length > 0 ? e.target.value : null);
																}}
															/>
														</Col>
													</React.Fragment>
												</Row>
											</form>
										</CardBody>
									</Row>
									<RoutesTable
										data={pickingsPages != null ? pickingsPages.results : []}
										isLoading={pickingsLoading}
										isSuccess={pickingsSuccess}
										error={error}
									/>
								</div>
								{pickingsPages && !isEmpty(pickingsPages.results) && (
									<Pagination
										totalRows={pickingsPages.count}
										pageChangeHandler={setCurrentPage}
										rowsPerPage={25}
									/>
								)}
							</CardBody>
						</Card>
					</Col>
				</Row>
				
			</Container>
		</div>
	);
};

export default withTranslation()(RoutesPage);

