import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Collapse,
  Table,
  Form,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
	FormFeedback
} from "reactstrap";

import * as moment from "moment";
import classnames from "classnames";
import { Link, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import { withTranslation } from 'react-i18next';

import BreadCrumb from "../../Components/Commons/BreadCrumb";
import Loader from "../../Components/Commons/Loader";
import OrderEventStatus from "./OrderEventStatus";
import DeleteModal from "../../Components/Commons/DeleteModal";
import OrderStatus from "./OrderStatus";
import OrderStatusIcon from "./OrderStatusIcon";

import { 
  useGetCollectTicketByIdQuery,
} from '../../api/api'


const CollectTicketDetail = ({ t, i18n }) => {

  let { id } = useParams();

  const { 
    data: collect,
    error,
    isFetching: isCollectLoading,
    isSuccess: isCollectSuccess
  } = useGetCollectTicketByIdQuery(
    id,
    {
      refetchOnMountOrArgChange: true,
      skip: false,
    }
  );

  useEffect(() => {
    if (collect) {
      debugger;
    }
  }, [collect]);

  const [deleteEventModal, setDeleteEventModal] = useState(false);

  const [orderEventToBeDeleted, setOrderEventToBeDeleted] = useState(null);
  const [colapseStates, setColapseStates] = useState(null);

  const [dontShowDeletedEvents, setDontShowDeletedEvents] = useState(true);
  const [orderEvents, setOrderEvents] = useState(null);
  const [createModal, setCreateModal] = useState(false);


  const deleteOrderEvent = () => {
    deleteEvent({id: orderEventToBeDeleted.id, order:id});
  };

  const colapseDetails = useCallback((key) => {
    let newColapseStates = colapseStates.map((c, i) => {
      if (i === key) {
        return !c;
      } else {
        return c;
      }
    });
    setColapseStates(newColapseStates);
  }, [colapseStates]);
  
  const handleValidDate = date => {
    const date1 = moment(date).format("DD/MM");
    return date1;
  };

  const handleValidTime = (time) => {
    const updateTime = moment(time).format('HH:mm');
    return updateTime;
  };

  const onChangeFilterSelect = (event) => {
    debugger;
    setDontShowDeletedEvents(event.target.value === 'true');
  };

  const toogle = useCallback(() => {
    if (createModal) {
      setCreateModal(false);
      validation.resetForm();
    } else {
      setCreateModal(true);
    }
  }, [createModal]);

  // validation
  const validation = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,

    initialValues: {
			user_id: '',
			datetime: '',
			event_id: '',
		},

    validationSchema: Yup.object({
      user_id: Yup.string().required("Please Enter User"),
      datetime: Yup.string().required("Please Enter a Event Datetime"),
      event_id: Yup.string().required("Please Enter Event"),
    }),
    onSubmit: (values) => {
      debugger
      const user = {
        id: values["user_id"]
      };
      const event = {
        id: values["event_id"]
      };
      const order = {
        id: id
      };
      const orderEvent = {
        user: user,
        event: event,
        order: order,
        datetime: moment(values["datetime"]).format()
      };
      debugger;
      createEvent({body: orderEvent});
    },
  });

  document.title ="Sistema de pedidos PROGISTICA";

  return (
    <div className="page-content">
      <Container fluid>        
        <BreadCrumb title={t("Collect Ticket Details")} pageTitle={t("Collect Tickets")} />
        <DeleteModal
          show={deleteEventModal}
          onDeleteClick={() => {
            deleteOrderEvent();
            setDeleteEventModal(false);
          }}
          onCloseClick={() => setDeleteEventModal(false)}
        />
        <Row>
          <Col xl={4}>
            <Card>
              <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">{t("Collect Ticket Detail")}</h4>
                <div className="flex-shrink-0">
                  {isCollectLoading && <Loader/>}
                  {isCollectSuccess && (
                    <Link
                      to={`edit`}
                      className="btn btn-success btn-sm"
                    >
                      <i className="ri-pencil-fill align-middle me-1"></i>{" "}
                      {t("Edit")}
                    </Link>
                  )}
                </div>
              </CardHeader>
              <CardBody>
                {isCollectLoading && <Loader/>}
                {isCollectSuccess && (
                  <div className="table-responsive table-card">
                    <Table className="table mb-0">
                      <tbody>
                        <tr>
                          <td className="fw-medium text-muted">{t("Collect Ticket Reference Id")}</td>
                          <td className="overview-industryType">{collect.reference}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium text-muted">{t("Order")}</td>
                          <td className="overview-industryType">
                            <Link to={`../order-sets/${collect.order_set.id}`} className="fw-medium link-primary">{collect.order_set.reference}</Link>
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-medium text-muted">{t("Emission date")}</td>
                          <td className="overview-industryType">
                            {collect.emission_date && handleValidDate(collect.emission_date)}
                          </td>
                        </tr>
                        {/*<tr>
                          <td className="fw-medium text-muted">{t("Billing day")}</td>
                          <td className="overview-industryType">
                            {collect.billing_date && handleValidDate(collect.billing_date)}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-medium text-muted">{t("Campaign")}</td>
                          <td className="overview-company_location">
                            {collect.campaign_reference}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-medium text-muted">{t("Territory")}</td>
                          <td className="overview-company_location">
                            {collect.territory}
                          </td>
                        </tr>*/}
                      </tbody>
                    </Table>
                  </div>
                )}
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <div className="d-flex">
                  <h5 className="card-title flex-grow-1 mb-0">
                    {t("Customer Details")}
                  </h5>
                </div>
              </CardHeader>
              <CardBody>
                {isCollectLoading && <Loader/>}
                {isCollectSuccess && (
                  <div className="table-responsive table-card">
                    <Table className="table table-borderless">
                      <tbody>
                        <tr>
                          <td className="fw-medium text-muted">{t("Name")}</td>
                          <td className="overview-industryType">
                            {collect.order_set.customer != null ? collect.order_set.customer.name : ''}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-medium text-muted">{t("Reference")}</td>
                          <td className="overview-company_location">
                            {collect.order_set.customer != null ? collect.order_set.customer.reference : ''}
                          </td>
                        </tr>
                        <tr>
                          <td className="fw-medium text-muted">{t("Address")}</td>
                          <td className="overview-company_location">
                            {collect.order_set.address != null ? collect.order_set.address.address_string : ''}
                          </td>
                        </tr>
                        
                      </tbody>
                    </Table>
                  </div>
                )}
              </CardBody>
            </Card>

            <Card>
              <CardHeader>
                <div className="d-flex align-items-center">
                  <h5 className="card-title flex-grow-1 mb-0">{t("Products")}</h5>
                </div>
              </CardHeader>
              <CardBody>
                {isCollectLoading && <Loader/>}
                {isCollectSuccess && (
                  <div className="table-responsive table-card">
                    <Table className="table table-borderless">
                      <tbody>
                        <tr>
                          <td className="fw-medium text-muted">{t("Quantity")}</td>
                          <td className="overview-company_location">{collect.quantity}</td>
                        </tr>
                        <tr>
                          <td className="fw-medium text-muted">{t("Products")}</td>
                          <td className="overview-company_location">{collect.products}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
          <Col xl={8}>
            

            {isCollectLoading && <Loader/>}
            {isCollectSuccess && collect.images_urls && collect.images_urls.length > 0 && (
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">{t("Images")}</h4>
                </CardHeader>

                <CardBody>
                  <div className="live-preview">
                    <div>
                      {isCollectSuccess && collect.images_urls.map((image, key) => (
                        <img key={key} src={image} className="img-fluid" alt="Responsive" />
                      ))}
                    </div>
                  </div>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withTranslation()(CollectTicketDetail);