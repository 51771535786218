import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Form, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane, FormFeedback } from 'reactstrap';
import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import { useGetUserByIdQuery } from '../../api/api'
import Loader from "../../Components/Commons/Loader";

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import { useGetDriverByIdQuery, useUpdateDriverMutation } from '../../api/api'

//import images
import progileBg from '../../images/profile-bg.jpg';
import avatar1 from '../../images/users/avatar-1.jpg';

const EditDriver = () => {

	const [activeTab, setActiveTab] = useState("1");

	const tabChange = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	let { id } = useParams();

	const { 
		data: driver,
		error,
		isFetching: isDriverLoading,
		isSuccess: isDriverSuccess
	} = useGetDriverByIdQuery(
		id,
		{
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

	const [
		updateDriver,
		{ 
			error: errors,
			isLoading: isUpdateLoading
		}] = useUpdateDriverMutation();

	// validation
	const validation = useFormik({
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: false,
		initialValues: {
			first_name: driver ? driver.user.first_name : '',
			rut: driver ? driver.rut : '',
			phone_number: driver ? driver.phone_number : '',
		},
		validationSchema: Yup.object({
			first_name: Yup.string().required("Please Enter First Name"),
			rut: Yup.string().required("Please Enter RUT"),
			phone_number: Yup.string().required("Please Enter Phone Number"),
		}),
		onSubmit: (values) => {
			const user = {
				email: values["email"],
				first_name: values["first_name"],
			};
			const driver = {
				user: user,
				rut: values["rut"],
				phone_number: values["phone_number"],
			};
			updateDriver({id: id, body: driver});
		},
	});

	document.title = "Profile Settings | Sistema de pedidos PROGISTICA";

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<div className="position-relative mx-n4 mt-n4">
						<div className="profile-wid-bg profile-setting-img">
							<img src={progileBg} className="profile-wid-img" alt="" />
						</div>
					</div>
					<Row>
						<Col xxl={3}>
							<Card className="mt-n5">
								<CardBody className="p-4">
									{isDriverLoading && <Loader/>}
									{isDriverSuccess && (
										<div className="text-center">
											<div className="profile-user position-relative d-inline-block mx-auto mb-4">
												<div className="avatar-xl img-thumbnail rounded-circle flex-shrink-0 user-profile-image">
													<div className="avatar-title text-uppercase border rounded-circle bg-light text-primary fs-24">
														{driver.user.first_name.charAt(0) + driver.user.last_name.charAt(0)}
													</div>
												</div>
											</div>
											
											<h5 className="fs-16 mb-1">{driver.user.first_name} {driver.user.last_name}</h5>
											<p className="text-muted mb-0">{driver.user.email}</p>
										</div>
									)}
								</CardBody>
							</Card>

						</Col>

						<Col xxl={9}>
							<Card className="mt-xxl-n5">
								<CardHeader>
									<Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
										role="tablist">
										<NavItem>
											<NavLink
												className={classnames({ active: activeTab === "1" })}
												onClick={() => {
													tabChange("1");
												}}>
												<i className="fas fa-home"></i>
												Personal Details
											</NavLink>
										</NavItem>
									</Nav>
								</CardHeader>
								<CardBody className="p-4">
									<TabContent activeTab={activeTab}>

										<TabPane tabId="1">
											<Form onSubmit={validation.handleSubmit}>
												<Row>
													<Col lg={12}>
														<div className="mb-3">
															<Label htmlFor="first_name" className="form-label">First Name</Label>
															<Input type="text" className="form-control" id="first_name" placeholder="Enter first name"
																name='first_name'
																validate={{
																	required: { value: true },
																}}
																onChange={validation.handleChange}
																onBlur={validation.handleBlur}
																value={validation.values.first_name || ""}
																invalid={
																	(validation.errors.first_name) || (errors && errors.first_name) ? true : false
																}
															/>
															{validation.touched.first_name && validation.errors.first_name ? (
																<FormFeedback type="invalid">{validation.errors.first_name}</FormFeedback>
															) : null}
															{errors && errors.first_name ? (<FormFeedback type="invalid">{errors.first_name}</FormFeedback>) : null}
														</div>
													</Col>
													<Col lg={6}>
															<div className="mb-3">
																<Label htmlFor="phone_number" className="form-label">Phone</Label>
																<Input type="text" className="form-control" id="phone_number" placeholder="Enter phone number"
																	name='phone_number'
																	validate={{
																		required: { value: true },
																	}}
																	onChange={validation.handleChange}
																	onBlur={validation.handleBlur}
																	value={validation.values.phone_number || ""}
																	invalid={
																		(validation.errors.phone_number) || (errors && errors.phone_number) ? true : false
																	}
																/>
																{validation.touched.phone_number && validation.errors.phone_number ? (
																	<FormFeedback type="invalid">{validation.errors.phone_number}</FormFeedback>
																) : null}
																{errors && errors.phone_number ? (<FormFeedback type="invalid">{errors.phone_number}</FormFeedback>) : null}
															</div>
														</Col>
														<Col lg={6}>
															<div className="mb-3">
																<Label htmlFor="rut" className="form-label">RUT</Label>
																<Input type="text" className="form-control" id="rut" placeholder="Enter RUT"
																	name='rut'
																	validate={{
																		required: { value: true },
																	}}
																	onChange={validation.handleChange}
																	onBlur={validation.handleBlur}
																	value={validation.values.rut || ""}
																	invalid={
																		(validation.errors.rut) || (errors && errors.rut)? true : false
																	}
																/>
																{validation.touched.rut && validation.errors.rut ? (
																	<FormFeedback type="invalid">{validation.errors.rut}</FormFeedback>
																) : null}
																{errors && errors.rut ? (<FormFeedback type="invalid">{errors.rut}</FormFeedback>) : null}
															</div>
														</Col>
													{/*<Col lg={12}>
														<div className="mb-3">
															<Label htmlFor="group" className="form-label">User Group</Label>
															<Input
																name="group"
																type="select"
																className="form-select"
																onChange={validation.handleChange}
																onBlur={validation.handleBlur}
																value={
																	validation.values.group || ""
																}
															>
															{validation.errors.group ? (
																<FormFeedback type="invalid">{validation.errors.group}</FormFeedback>
															) : null}
															{usersGroups.map((item, key) => (
																<React.Fragment key={key}>
																{item.options.map((item, key) => (<option value={item.value} key={key}>{item.label}</option>))}
																</React.Fragment>
															))}
															{errors && errors.group ? (<FormFeedback type="invalid">{errors.group}</FormFeedback>) : null}
															</Input>
														</div>
													</Col>*/}
													<Col lg={12}>
														<div className="hstack gap-2 justify-content-end">
															<button type="button" className="btn btn-light" >Close</button>
															<button type="submit" className="btn btn-success" id="addNewUser">Save</button>
														</div>
													</Col>
												</Row>
											</Form>
										</TabPane>

										<TabPane tabId="2">
											<Form>
												<Row className="g-2">
													<Col lg={4}>
														<div>
															<Label htmlFor="oldpasswordInput" className="form-label">Old
																Password*</Label>
															<Input type="password" className="form-control"
																id="oldpasswordInput"
																placeholder="Enter current password" />
														</div>
													</Col>

													<Col lg={4}>
														<div>
															<Label htmlFor="newpasswordInput" className="form-label">New
																Password*</Label>
															<Input type="password" className="form-control"
																id="newpasswordInput" placeholder="Enter new password" />
														</div>
													</Col>

													<Col lg={4}>
														<div>
															<Label htmlFor="confirmpasswordInput" className="form-label">Confirm
																Password*</Label>
															<Input type="password" className="form-control"
																id="confirmpasswordInput"
																placeholder="Confirm password" />
														</div>
													</Col>

													<Col lg={12}>
														<div className="mb-3">
															<Link to="#"
																className="link-primary text-decoration-underline">Forgot
																Password ?</Link>
														</div>
													</Col>

													<Col lg={12}>
														<div className="text-end">
															<button type="button" className="btn btn-success">Change
																Password</button>
														</div>
													</Col>

												</Row>

											</Form>
											
										</TabPane>

									</TabContent>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default EditDriver;