import React, { useState, useEffect } from 'react';
import {  useParams } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Container, Form, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane, FormFeedback } from 'reactstrap';
import classnames from "classnames";
import { useTranslation } from "react-i18next";
 
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import { useGetFilterSettingQuery, useUpdateFilterSettingMutation } from '../../api/api'

//import images
import progileBg from '../../images/profile-bg.jpg';

const SystemSettings = () => {

	const [activeTab, setActiveTab] = useState("1");

	const tabChange = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	const { t } = useTranslation();

	let { id } = useParams();

	const { 
		data: filterSettings,
		error,
		isFetching: isUserLoading,
		isSuccess: isUserSuccess
	} = useGetFilterSettingQuery(
		id,
		{
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

  useEffect(() => {
		if (filterSettings) {
			debugger;
			//let asdasde = filterSettings[0].value.toString();
		}
  }, [filterSettings]);

	const [
		updateFilterSetting, 
		{ 
			error: errors,
			isLoading: isUpdateLoading
		}] = useUpdateFilterSettingMutation();

	// validation
	const validation = useFormik({
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: false,
		initialValues: {
			zones_filter: filterSettings != null && filterSettings[0] != null && filterSettings[0] !== undefined && filterSettings[0].value != null ? filterSettings[0].value.join(', ') : '',
		},
		onSubmit: (values) => {
			let newFilter = values['zones_filter'];
			const array = newFilter.split(',');
			for (let a in array) {
				array[a] = array[a].trim();
			}
			debugger;
			updateFilterSetting({id: filterSettings[0].id, body: array});
			debugger;
		},
	});

	document.title = "Profile Settings | Sistema de pedidos PROGISTICA";

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<div className="position-relative mx-n4 mt-n4">
						<div className="profile-wid-bg profile-setting-img">
							<img src={progileBg} className="profile-wid-img" alt="" />
						</div>
					</div>
					<Row>
						
						<Col xxl={12}>
							<Card className="mt-xxl-n5">
								<CardHeader>
									<Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
										role="tablist">
										<NavItem>
											<NavLink
												className={classnames({ active: activeTab === "1" })}
												onClick={() => {
													tabChange("1");
												}}>
												<i className="fas fa-home mr-2"></i>
												{t("System Settings")}
											</NavLink>
										</NavItem>
									</Nav>
								</CardHeader>
								<CardBody className="p-4">
									<TabContent activeTab={activeTab}>

										<TabPane tabId="1">
											<Form onSubmit={validation.handleSubmit}>
												<Row>
													<Col lg={6}>
														<div className="mb-3">
															<Label htmlFor="zones_filter" className="form-label">{t("Zones that PROGISTICA works")}</Label>
															<Input type="text" className="form-control" id="zones_filter" placeholder={t("Enter zones comma separated")}
																name='zones_filter'
																validate={{
																	required: { value: true },
																}}
																onChange={validation.handleChange}
																onBlur={validation.handleBlur}
																value={validation.values.zones_filter || ""}
																invalid={
																	(validation.errors.zones_filter) || (errors && errors.zones_filter) ? true : false
																}
															/>
															{validation.touched.zones_filter && validation.errors.zones_filter ? (
																<FormFeedback type="invalid">{validation.errors.zones_filter}</FormFeedback>
															) : null}
															{errors && errors.zones_filter ? (<FormFeedback type="invalid">{errors.zones_filter}</FormFeedback>) : null}
														</div>
													</Col>
													
													<Col lg={12}>
														<div className="hstack gap-2 justify-content-end">
															<button type="button" className="btn btn-light" >{t("Close")}</button>
															<button type="submit" className="btn btn-success" id="addNewUser">{t("Save")}</button>
														</div>
													</Col>
												</Row>
											</Form>
										</TabPane>

									</TabContent>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default SystemSettings;