import React, { useState, useCallback } from "react";
import {
	Card,
	Col,
	Container,
	Form,
	Input,
	Row,
} from "reactstrap";

import JobProfile2 from "../../images/job-profile2.png";

import { useNavigate } from "react-router-dom";

const Home = () => {

	const [search, setSearch] = useState(null);
	const [searchPressed, setSearchPressed] = useState(false);

	const navigate = useNavigate();

	const onSearchPressed = useCallback((value) => {
		if(search && search !== ""){
			setSearchPressed(true);
			if (search.length == 13){
				const real_search = search.substring(0, 10);
				navigate(`${real_search}`);
			} else {
				navigate(`${search}`);
			}
		}
	});

	const onSearchChange = useCallback((value) => {
		setSearch(value);
	});

	return (
		<React.Fragment>
			<section className="section job-hero-section bg-light pb-0" id="hero">
				<Container>
					<Row className="justify-content-between">
						<Col lg={6} className="justify-content-between mt-4 pt-4">
							<div>
								<h1 className="display-6 fw-semibold mb-3 lh-base">
									Realiza tu seguimiento en línea
								</h1>
								<p className="lead text-muted lh-base mb-4">
									Ingresa tu número de consultora en la siguiente casilla
								</p>
								<Form action="#" className="job-panel-filter">
									<Row className="g-md-0 g-2">
										<Col className="col-md-8">
											<div>
												<Input
													type="search"
													id="order-code"
													className="form-control form-control-lg filter-input-box"
													placeholder="Número de consultora"
													onChange={(e) => onSearchChange(e.target.value)} 
												/>
											</div>
										</Col>
										<Col className="col-md-4">
											<div className="h-100">
												<button
													className="btn btn-lg btn-success submit-btn w-100 h-100"
													type="submit"
													disabled={searchPressed}
													onClick={(e) => {
														onSearchPressed(e.target.value);
													}}
												>
													<i className="ri-search-2-line align-bottom me-1"></i>{" "}
													Buscar
												</button>
											</div>
										</Col>
									</Row>
								</Form>

								<ul className="treding-keywords list-inline mb-0 mt-3 fs-13">
									<li className="list-inline-item text-danger fw-semibold">
										<i className="mdi mdi-tag-multiple-outline align-middle"></i>{" "}
										Obtén:
									</li>
									<li className="list-inline-item">
										Estado de la entrega,
									</li>
									<li className="list-inline-item">
										Información de despacho,
									</li>
									<li className="list-inline-item">
										Información de entrega
									</li>
								</ul>
							</div>
						</Col>
						<Col lg={4}>
							<div className="position-relative home-img text-center mt-5 mt-lg-0">
								<Card className="card-bg-fill p-3 rounded shadow-lg inquiry-box">
									<div className="d-flex align-items-center">
										<div className="avatar-sm flex-shrink-0 me-3">
											<div className="avatar-title bg-soft-warning text-warning rounded fs-18">
												<i className="ri-flashlight-line"></i>
											</div>
										</div>
										<h5 className="fs-14 lh-base mb-0">
											Información al instante
										</h5>
									</div>
								</Card>

								<img src={JobProfile2} alt="" className="user-img" />

								<div className="circle-effect">
									<div className="circle"></div>
									<div className="circle2"></div>
									<div className="circle3"></div>
									<div className="circle4"></div>
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</section>
		</React.Fragment>
	);
};

export default Home;
