import React from 'react';

/*
WAITING_AUTHORIZATION = 1
AUTHORIZED = 2
DENIED = 3
*/

//Constants
const PickingCompletionRequestStatus = ({ status, status_text}) => {
	if (status === 1) {
		return <div className={"badge bg-warning"}>{status_text}</div>;
	} else if (status === 2) {
		return <div className={"badge bg-success"}>{status_text}</div>;
	} else if (status === 3) {
		return <div className={"badge badge-soft-danger"}>{status_text}</div>;
	} else if (status === 0) {
		return <div className={"badge badge-soft-danger"}>{status_text}</div>;
	}
};

export default PickingCompletionRequestStatus;