import React, { Fragment, useState, useMemo, useCallback } from "react";
import { withTranslation } from 'react-i18next';
import * as moment from "moment";
import { Link } from "react-router-dom";
import { Button } from 'reactstrap';
import {
  Input
} from "reactstrap";
import { hasGroup } from "../../api/session_helper";
import OrdersTableBase from "../../Components/Commons/OrdersTableBase";
import OrderStatus from "./OrderStatus";
import OrderSetKind from "./OrderSetKind";
import 'react-toastify/dist/ReactToastify.css';

const ClientOrderSetsTable = ({
  data,
  isLoading,
  isSuccess,
  error,
  onSelected,
  onDelete,
  t,
  i18n
}) => {

  const [selectedItems, setSelectedItems] = useState([]);

  /*const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBox.forEach((element) => {
      dispatch(onDeleteOrder(element.value));
      setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
    });
    checkall.checked = false;
    setIsMultiDeleteButton(false);
  };

  const deleteCheckbox = (order) => {
    if(selectedCheckBox.includes(parseInt(order))){
      let length = selectedCheckBox.length - 1;
      setSelectedCheckBox(
        selectedCheckBox.filter(id => id !== parseInt(order))
      );
      onMultipleSelected(length);
    } else {
      setSelectedCheckBox([
        ...selectedCheckBox,
        parseInt(order)
      ]);
      let length = selectedCheckBox.length + 1;
      onMultipleSelected(length);
    }
  };

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const toggle = useCallback(() => {
    
  });*/


  const handleValidDate = date => {
    const date1 = moment(date).format("DD/MM");
    return date1;
  };

  const handleValidTime = (time) => {
    const updateTime = moment(time).format('HH:mm');
    return updateTime;
  };
  
  // Column
  const columns = useMemo(
    () => [
      {
        Header: "Pedido",
        accessor: "reference",
        filterable: false,
        Cell: (cell) => {
          return <Link to={`${cell.row.original.reference}`} className="fw-medium link-primary h5">{cell.value}</Link>;
        },
      },
      {
        Header: "Consultora",
        accessor: "customer",
        filterable: false,
        Cell: (cell) => (
          <>
            <h5 className="fs-14 mb-1">
              {cell.row.original.customer != null ? cell.row.original.customer.reference : ''} - {cell.row.original.customer != null ? cell.row.original.customer.name : ''}
            </h5>
            <p className="text-muted mb-0">
              <small className="text-muted"> {cell.row.original.address != null ? cell.row.original.address.address_string.substring(0, 50) : ''}</small>
            </p>
          </>
        ),
      },
      {
        Header: "Día de Facturación",
        accessor: "billing_date",
        Cell: (order) => {
          return handleValidDate(order.row.original.billing_date);
        },
      },
      {
        Header: "Fecha de entrega",
        accessor: "delivery_datetime",
        Cell: (order) => {
          if(order.row.original.delivery_datetime){
            return <>
              {handleValidDate(order.row.original.delivery_datetime)},
              <small className="text-muted"> {handleValidTime(order.row.original.delivery_datetime)}</small>
            </>;
          } else {
            return '--';
          }
        },
      },
      {
        Header: "Cajas",
        accessor: "box_quantity",
        filterable: false,
      },
      {
        Header: "Fueras de cajas",
        accessor: "quantity",
        filterable: false,
      },
      {
        Header: "Campaña",
        accessor: "campaign_reference",
        filterable: false,
      },
      {
        Header: "Zona",
        accessor: "zone",
        filterable: false,
      },
      {
        Header: "Recojo",
        Cell: (order) => {
          return <OrderSetKind collect_tickets={order.row.original.collect_tickets} />;
        }
      },
      {
        Header: "Tipo",
        accessor: 'kind',
        Cell: (order) => {
          return order.row.original.kind;
        }
      },
      {
        Header: "Estado",
        accessor: 'status',
        Cell: (order) => {
          return <OrderStatus status={order.row.original.status} status_text={order.row.original.status_text} />;
        }
      },
      {
        Header: "Acción",
        Cell: (cellProps) => {
          return (
            <Link to={`${cellProps.row.original.reference}`} className="btn btn-sm btn-primary text-center w-100">Ver</Link>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <OrdersTableBase
        columns={columns}
        data={data}
        isLoading={isLoading}
        isSuccess={isSuccess}
        error={error}
        manualPagination={true}
        customPageSize={25}
        divClass="table-responsive table-card mb-1"
        tableClass="align-middle table-nowrap"
        theadClass="table-light text-muted"
      />
    </>
  );
};

export default withTranslation()(ClientOrderSetsTable);

