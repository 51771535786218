import React, { Fragment, useState, useMemo, useCallback } from "react";
import * as moment from "moment";
import { Link } from "react-router-dom";

import OrdersTableBase from "../../Components/Commons/OrdersTableBase";
import { hasGroup } from "../../api/session_helper";
import { useTranslation } from "react-i18next";

import 'react-toastify/dist/ReactToastify.css';

const OutOfBoxTable = ({
  data,
  isLoading,
  isSuccess,
  error,
  onMultipleSelected,
}) => {

  const { t } = useTranslation();

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const handleOrderClick = useCallback((arg) => {
    const order = arg;
    setOrder({
      _id: order._id,
      orderId: order.orderId,
      customer: order.customer,
      product: order.product,
      orderDate: order.orderDate,
      ordertime: order.ordertime,
      amount: order.amount,
      payment: order.payment,
      status: order.status
    });

    setIsEdit(true);
  }, []);

  const handleValidDate = date => {
    const date1 = moment(date).format("DD/MM");
    return date1;
  };

  const handleValidTime = (time) => {
    const updateTime = moment(time).format('HH:mm');
    return updateTime;
  };
            
  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Name"),
        accessor: "afp_name",
        filterable: false,
        Cell: (cell) => {
          return <div>{cell.row.original.afp_name ?? cell.row.original.afp_name}</div>
        },
      },
      {
        Header: t("Quantity"),
        accessor: "quantity",
        Cell: (order) => (
          <>
            {order.row.original.quantity}
          </>
        ),
      },
    ],
    [handleOrderClick]
  );

  return (
    <>
      <OrdersTableBase
        columns={columns}
        data={data}
        isLoading={isLoading}
        isSuccess={isSuccess}
        error={error}
        manualPagination={true}
        customPageSize={25}
        divClass="table-responsive table-card mb-1"
        tableClass="align-middle table-nowrap"
        theadClass="table-light text-muted"
      />
    </>
  );
};

export default OutOfBoxTable;

