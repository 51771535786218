import React from 'react';
import { Container, Col, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Commons/BreadCrumb';
import OrderSetOrders from './OrderSetOrders';
import OrderSetSummary from "./OrderSetSummary";
import { withTranslation } from 'react-i18next';

const OrderSetDetail = ({ t, i18n }) => {
	document.title ="Sistema de pedidos PROGISTICA";
	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<BreadCrumb title={t("Order Details")} pageTitle={t("Orders")} />
					<Row>
						<Col xl={4}>
							<OrderSetSummary />
						</Col>
						<Col xl={8}>
							<OrderSetOrders />
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default withTranslation()(OrderSetDetail);