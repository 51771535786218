import React, { useEffect, useState, useCallback, useRef } from "react";
import { withTranslation } from 'react-i18next';
import {
	Card,
	CardBody,
	Col,
	Container,
	CardHeader,
	Row,
	Table,
} from "reactstrap";

import * as moment from "moment";
import { useParams } from "react-router-dom";

import BreadCrumb from "../../Components/Commons/BreadCrumb";
import Loader from "../../Components/Commons/Loader";
import ErrorModal from "../../Components/Commons/ErrorModal";
import ConfirmModal from "../../Components/Commons/ConfirmModal";
import PickingStatus from "../Pickings/PickingStatus";
import OrderSetsTable from "./OrderSetsTable";
import Pagination from "../../Components/Commons/Pagination";
import { hasGroup } from "../../api/session_helper";
import _ from 'lodash';
import { useNavigate } from "react-router-dom";

import 'react-toastify/dist/ReactToastify.css';

import { 
	useGetPickingByIdQuery, 
	useCreatePickingMutation,
	useGetPickingPickedOutOrdersQuery
 } from '../../api/api'

const RouteDetails = ({ t, i18n }) => {

	let { id } = useParams();

	const navigate = useNavigate();

	const ref = useRef(null);

	const [currentPage, setCurrentPage] = useState(1);
	
	const [orders, setOrders] = useState([]);
	const [errorModal, setErrorModal] = useState(false);
	const [errorTitle, setErrorTitle] = useState(false);
	const [errorDescription, setErrorDescription] = useState(false);
	const [createReturnsPickingModal, setCreateReturnsPickingModal] = useState(false);

	const [createPicking, { 
		error: pickingCreationErrors, 
		isLoading: pickingCreationLoading,
		isSuccess: pickingCreationSuccess,
		data: createdPicking
	}] = useCreatePickingMutation();

	useEffect(() => {
		if (pickingCreationSuccess) {
			setCreateReturnsPickingModal(false);
			navigate(`../../pickings/${createdPicking.id}`);
		}
	}, [pickingCreationSuccess]);

	const { 
		data: picking,
		error,
		isFetching: isPickingLoading,
		isSuccess: isPickingSuccess
	} = useGetPickingByIdQuery(
		id,
		{
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

	const { 
		data: orderSetsPages,
		error: isOrderSetsError,
		isFetching: isOrderSetsLoading,
		isSuccess: isOrderSetsSuccess
	} = useGetPickingPickedOutOrdersQuery(
		{
			id: id,
			page: currentPage
		},
		{
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

	useEffect(() => {
		if (picking) {

			/* outbound */
			if(picking.kind === 1){
				setOrders(picking.route_orders);
			}
		}
	}, [picking]);

	useEffect(() => {
		if(error){
			setErrorTitle(t("Error when trying to obtain information of the route"));
			setErrorDescription(error);
			setErrorModal(true);
		}
	}, [error]);

	const handleValidDate = date => {
		const date1 = moment(date).format("DD/MM");
		return date1;
	};

	const handleValidTime = (time) => {
		const updateTime = moment(time).format('HH:mm');
		return updateTime;
	};

	const handleCreateReturnsPicking = () => {
		const newInputPicking = {
			kind: 2,
			picking_out_id: picking.id
		};
		createPicking(newInputPicking);
	};

	document.title="Sistema de pedidos PROGISTICA";

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<BreadCrumb title={t("Route Details")} pageTitle={t("Routes")} />
					<ErrorModal
						show={errorModal}
						onCloseClick={() => setErrorModal(false)}
						title={errorTitle}
						description={errorDescription}
					/>
					<ConfirmModal
						show={createReturnsPickingModal}
						onConfirmClick={() => handleCreateReturnsPicking()}
						onCloseClick={() => setCreateReturnsPickingModal(false)}
						title={t("Create new return picking")}
						question={t("Do you want to create an Returns Picking linked to you?")}
						yesQuestion={t("Yes, create it!")}
					/>
					<Row>
						<Col xxl={3}>
							<Card id="picking-overview">
								<CardHeader className="align-items-center d-flex">
									<h4 className="card-title mb-0 flex-grow-1">{t("Route Information")}</h4>
								</CardHeader>
								<CardBody>
									{isPickingLoading && <Loader/>}
									{isPickingSuccess && (
										<div className="table-responsive table-card">
											<Table className="table mb-0">
												<tbody>
													<tr>
														<td className="fw-medium text-muted">{t("Route Reference ID")}</td>
														<td className="overview-industryType">{parseInt(id)}</td>
													</tr>
													<tr>
														<td className="fw-medium text-muted">{t("Driver")}</td>
														<td className="overview-industryType">
															{picking.driver.name}
														</td>
													</tr>
													<tr>
														<td className="fw-medium text-muted">{t("Created at")}</td>
														<td className="overview-company_location">
															{handleValidDate(picking.created_at)} {handleValidTime(picking.created_at)}
														</td>
													</tr>
													<tr>
														<td className="fw-medium text-muted">{t("Updated at")}</td>
														<td className="overview-company_location">
															{handleValidDate(picking.updated_at)} {handleValidTime(picking.updated_at)}
														</td>
													</tr>
													<tr>
														<td className="fw-medium text-muted">{t("Picking status")}</td>
														<td className="overview-company_location">
															<PickingStatus status={picking.status} status_text={picking.status_text} />
														</td>
													</tr>
												</tbody>
											</Table>
											{hasGroup(['Manager', 'OperationalManager', 'RouteManager', 'ReverseLogisticsOperator']) && picking.status === 3 && (
												<div className="p-2 pt-2">
													<button
														type="button"
														className="btn btn-primary w-100"
														id="create-btn"
														onClick={() => { setCreateReturnsPickingModal(true); }}>
														<i className="ri-add-line align-bottom me-1"></i> {t("Create Returns Picking")}
													</button>
												</div>
											)}
										</div>
									)}
								</CardBody>
							</Card>
						</Col>

						<Col xl={9}>
							<Card className="card-height-100">
								<CardHeader className="align-items-center d-flex">
									<h4 className="card-title mb-0 flex-grow-1">{t("Orders")}</h4>
								</CardHeader>
								<CardBody>
									<OrderSetsTable
										data={orderSetsPages != null ? orderSetsPages.results : []}
										isLoading={isOrderSetsLoading}
										isSuccess={isOrderSetsSuccess}
										error={isOrderSetsError}
									/>
									{orderSetsPages && orderSetsPages.results.length > 0 && (
										<Pagination
											totalRows={orderSetsPages.count}
											pageChangeHandler={setCurrentPage}
											rowsPerPage={25}
										/>
									)}
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default withTranslation()(RouteDetails);