import React, { Fragment, useState, useMemo, useCallback } from "react";
import * as moment from "moment";
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import OrdersTableBase from "../../Components/Commons/OrdersTableBase";
import OrderStatus from "../OrderSets/OrderStatus";

import 'react-toastify/dist/ReactToastify.css';

const OrdersTable = ({
  data,
  isLoading,
  isSuccess,
  error,
  onMultipleSelected,
  t,
  i18n
}) => {

  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".orderCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(onDeleteOrder(element.value));
      setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
    });
    checkall.checked = false;
    setIsMultiDeleteButton(false);
  };

  const deleteCheckbox = (order) => {
    if(selectedCheckBoxDelete.includes(parseInt(order))){
      let length = selectedCheckBoxDelete.length - 1;
      setSelectedCheckBoxDelete(
        selectedCheckBoxDelete.filter(id => id !== parseInt(order))
      );
      onMultipleSelected(length);
    } else {
      setSelectedCheckBoxDelete([
        ...selectedCheckBoxDelete,
        parseInt(order)
      ]);
      let length = selectedCheckBoxDelete.length + 1;
      onMultipleSelected(length);
    }
  };

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const toggle = useCallback(() => {
    
  });

  const handleOrderClick = useCallback((arg) => {
    const order = arg;
    setOrder({
      _id: order._id,
      orderId: order.orderId,
      customer: order.customer,
      product: order.product,
      orderDate: order.orderDate,
      ordertime: order.ordertime,
      amount: order.amount,
      payment: order.payment,
      status: order.status
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);

  const handleValidDate = date => {
    const date1 = moment(date).format("DD/MM");
    return date1;
  };

  const handleValidTime = (time) => {
    const updateTime = moment(time).format('HH:mm');
    return updateTime;
  };
  
  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Order Id"),
        accessor: "reference",
        filterable: false,
        Cell: (cell) => {
          return <Link to={`../boxes/${cell.row.original.id}`} className="fw-medium link-primary h5">{parseInt(cell.value)}</Link>;
        },
      },
      {
        Header: t("Created at"),
        accessor: "created_at",
        Cell: (order) => (
          <>
            {handleValidDate(order.row.original.created_at)},
            <small className="text-muted"> {handleValidTime(order.row.original.created_at)}</small>
          </>
        ),
      },
      {
        Header: t("Billing Day"),
        accessor: "billing_date",
        Cell: (order) => {
          if(order.row.original.operational_block){
            return <Link to={`../operational-blocks/${order.row.original.operational_block.id}`} className="link-primary">{handleValidDate(order.row.original.billing_date)}</Link>
          } else {
            return handleValidDate(order.row.original.billing_date);
          }
        },
      },
      {
        Header: t("Campaign Id"),
        accessor: "campaign_reference",
        filterable: false,
      },
      {
        Header: t("Zone Id"),
        accessor: "zone",
        filterable: false,
      },
      {
        Header: t('Status'),
        accessor: 'status',
        Cell: (order) => {
          return <OrderStatus status={order.row.original.status} status_text={order.row.original.status_text} />;
        }
      },
    ],
    [handleOrderClick, checkedAll]
  );

  return (
    <>
      <OrdersTableBase
        columns={columns}
        data={data}
        isLoading={isLoading}
        isSuccess={isSuccess}
        error={error}
        manualPagination={true}
        customPageSize={25}
        divClass="table-responsive table-card mb-1"
        tableClass="align-middle table-nowrap"
        theadClass="table-light text-muted"
      />
    </>
  );
};

export default withTranslation()(OrdersTable);

