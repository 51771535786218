import React from 'react';
import FeatherIcon from "feather-icons-react";
import { withTranslation } from 'react-i18next';
/*
DELETED = 0
ENTERED = 1
WAITING_REVIEW = 2
COMPLETED = 3
*/

//Constants
const PickingKind = ({ status, status_text, t, i18n}) => {
	if (status === 0) {
		return <div className="d-flex">
			<span className="badge badge-outline-success">{t("Inbound")}</span>
			<FeatherIcon
				icon="arrow-down-circle"
				className="icon-dual-success icon-sm mx-2"
			/>
		</div>;
	} else if (status === 1) {
		return <div className="d-flex">
			<span className="badge badge-outline-primary">{t("Outbound")}</span>
			<FeatherIcon
				icon="arrow-up-circle"
				className="icon-dual-primary icon-sm mx-2"
			/>
		</div>;
	} else if (status === 2) {
		return <div className="d-flex">
			<span className="badge badge-outline-warning">{t("Returns")}</span>
			<FeatherIcon
				icon="rotate-cw"
				className="icon-dual-warning icon-sm mx-2"
			/>
		</div>;
	}
};

export default withTranslation()(PickingKind);