import React from 'react';

/*
    CANCELLED = -1
    ENTERED = 0
    STARTED = 1
	ARRIVED = 4
    COMPLETED = 2
    SKIPPED = 3
    DELETED = 5
*/

//Constants
const OptirouteOrderStatus = ({ status, status_text}) => {
	if (status === 0) {
		return <div className={"badge bg-primary"}>{status_text}</div>;
	} else if (status === 1) {
		return <div className={"badge bg-secondary"}>{status_text}</div>;
	} else if (status === 4) {
		return <div className={"badge bg-info"}>{status_text}</div>;
	} else if (status === 2) {
		return <div className={"badge bg-success"}>{status_text}</div>;
	} else if (status === 3) {
		return <div className={"badge bg-warning"}>{status_text}</div>;
	} else if (status === 5) {
		return <div className={"badge bg-danger"}>{status_text}</div>;
	} else if (status === -1) {
		return <div className={"badge badge-soft-danger"}>{status_text}</div>;
	}
};

export default OptirouteOrderStatus;