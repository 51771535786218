import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Label,
  Modal,
	ModalBody,
	ModalHeader,
	Form,
  Card,
  Row,
  Col,
  FormFeedback, 
  Alert
} from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import Loader from "../../Components/Commons/Loader";
import { useTranslation } from "react-i18next";

import { 
  useImportOrdersMutation,
  useGetAllCompaniesQuery
} from '../../api/api'

const ImportOrdersModal = ({ 
  show, 
  onClose, 
  onOrderImportSuccess, 
  onOrderImportError
}) => {

  const { t } = useTranslation();

  const { 
    data: companies,
    error: companiesError,
    isFetching: companiesLoading,
    isSuccess: companiesSuccess
  } = useGetAllCompaniesQuery({
    refetchOnMountOrArgChange: true,
    skip: false,
  });

  const [selectedFiles, setSelectedFiles] = useState([]);
	const [rejectedFiles, setRejectedFiles] = useState([]);

	const [importOrders, 
  { data: importObject, 
    error: importErrors, 
    isLoading: isImportLoading, 
    isSuccess: isImportSuccess
  }] = useImportOrdersMutation();

  useEffect(() => {
    if (companiesSuccess) {
      validation.setFieldValue('company_id', companies && companies.length > 0 ? companies[0].id : null)
    }
  }, [companiesSuccess]);

  useEffect(() => {
    if (isImportSuccess) {
      onOrderImportSuccess(importObject);
    }
    if (importErrors) {
      onOrderImportError(importErrors);
    }

  }, [isImportSuccess, importErrors]);

  useEffect(() => {
    setSelectedFiles([]);
  }, [show]);
  
	function handleAcceptedFiles(files) {
		files.map((file) =>
		Object.assign(file, {
				formattedSize: formatBytes(file.size),
			})
		);
		setSelectedFiles(files);
	}

  function handleRejectedFiles(files) {
		files.map((file) =>
			Object.assign(file, {
				formattedSize: formatBytes(file.size),
			})
		);
		setRejectedFiles(files);
	}

  function hasExtension(name, exts) {
    return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(name.toLowerCase());
	}

  function formatBytes(bytes, decimals = 2) {
		if (bytes === 0) return "0 Bytes";
		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
	
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function extensionValidator(file) {
		if (!hasExtension(file.name, ['.xlsx', '.xls'])) {
			return {
				code: "wrong-format",
				message: t(`The selected file does not have the required format`)
			};
		}
		return null
	}

  useEffect(() => {
    if (companiesSuccess) {
      validation.setFieldValue('company_id', companies && companies.length > 0 ? companies[0].id : null)
    }
  }, [companiesSuccess]);
  
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    //enableReinitialize: true,
  
    initialValues: {
      company_id: companies && companies.length > 0 ? companies[0].id : null,
    },
    
    validationSchema: Yup.object({
      company_id: Yup.string().required(t("Please Enter company Id")),
    }),
  
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("company_id", values['company_id']);
      if(selectedFiles.length > 0){
        formData.append("file", selectedFiles[0]);
        importOrders(formData);
        validation.resetForm();
      }
    },
  });

  return (
    <Modal id="showModal" isOpen={show} toggle={onClose} centered>
      <ModalHeader className="bg-light p-3" >
        {t('Import Orders')}
      </ModalHeader>
      <Form className="tablelist-form" onSubmit={validation.handleSubmit}>
        {isImportLoading && <Loader/>}
        {!isImportLoading && <ModalBody>
          <div>
            <div className="mb-3">
            <Label
              htmlFor="choices-publish-status-input"
              className="form-label"
            >
              {t('Company')}
            </Label>

            {companies && <Select
              required
              classNamePrefix="form-select"
              isLoading={companiesLoading}
              isClearable={true}
              name="types"
              defaultValue={companies && companies.length > 0 ? { label: companies[0].name, value: companies[0].id } : null}
              onChange={(selection) => {validation.setFieldValue('company_id', selection ? selection.id : null)}}
              options={companies.map((company, key) => {
                return {
                  ...company,
                  value: company.id,
                  label: company.name,
                };
              })}
            />}

            {validation.touched.companyId && validation.errors.companyId ? (
              <FormFeedback type="invalid">{validation.errors.companyId}</FormFeedback>
            ) : null}
          </div>

          <div className="mb-3"></div>
            <Label
              htmlFor="choices-publish-status-input"
              className="form-label"
            >
              {t('File')}
            </Label>

            <Dropzone
              onDropAccepted={(acceptedFiles) => {
                handleRejectedFiles([]);
                handleAcceptedFiles(acceptedFiles);
              }}
              onDropRejected={(rejectededFiles) => {
                handleAcceptedFiles([]);
                handleRejectedFiles(rejectededFiles);
              }}
              maxFiles={1}
              validator={extensionValidator}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone dz-clickable">
                  <div
                    className="dz-message needsclick"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <div className="mb-3">
                      <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                    </div>
                    <h5>{t("Drop files here or click to upload.")}</h5>
                  </div>
                </div>
              )}
            </Dropzone>

            <div className="list-unstyled mb-0" id="file-previews">
              {selectedFiles.map((f, i) => {
                return (
                  <Card
                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    key={i + "-file"}
                  >
                    <div className="p-2">
                    <Row className="align-items-center">
                      <Col>
                      <Link
                        to="#"
                        className="text-muted font-weight-bold"
                      >
                        {f.name}
                      </Link>
                      <p className="mb-0">
                        <strong>{f.formattedSize}</strong>
                      </p>
                      </Col>
                    </Row>
                    </div>
                  </Card>
                );
              })}
            </div>

            {rejectedFiles.length > 0 ? (
              <div className="list-unstyled mb-0" id="file-previews">
                {rejectedFiles.map((f, i) => {
                  return (
                    <Card
                      className="mt-1 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete alert alert-danger mb-xl-0"
                      key={i + "-file"}
                    >
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col>
                            <Link
                              to="#"
                              className="text-muted font-weight-bold"
                            >
                              {f.errors[0].message}
                            </Link>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  );
                })}
              </div>
            ) : null}
          </div>
        </ModalBody>}
        <div className="modal-footer">
          <div className="hstack gap-2 justify-content-end">
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                onClose();
              }}
            >
              {t("Close")}
            </button>

            <button type="submit" className="btn btn-success" disabled={isImportLoading || selectedFiles.length == 0}>
              {t("Import")}
            </button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default ImportOrdersModal;