import React from 'react';

//Constants
const OrderExportStatus = ({ status, status_text}) => {
	/*ERROR = 1
    ENTERED = 2
    IN_PROGRESS = 3
    SUCCESS = 4
    VALIDATION_ERROR = 5*/
	if(status === 1){
		return <div className={"badge badge-soft-danger"}>{status_text}</div>;
	} else if (status === 2) {
		return <div className={"badge badge-soft-primary"}>{status_text}</div>;
	} else if (status === 3) {
		return <div className={"badge badge-soft-secondary"}>{status_text}</div>;
	} else if (status === 4) {
		return <div className={"badge badge-soft-success"}>{status_text}</div>;
	} else if (status === 5) {
		return <div className={"badge badge-soft-warning"}>{status_text}</div>;
	}
};

export default OrderExportStatus;