import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { hasGroup } from "../../api/session_helper";
import { logoutUser } from "../../slices/thunks";

//redux
import { useSelector, useDispatch } from "react-redux";

import withRouter from "../../Components/Commons/withRouter";

import { orderApi } from '../../api/api';

const Logout = (props) => {
  const dispatch = useDispatch();

  const { isUserLogout } = useSelector((state) => ({
    isUserLogout: state.Login.isUserLogout,
  }));

  useEffect(() => {
    orderApi.util.invalidateTags([
      'Orders', 
      'OrderImports', 
      'Users', 
      'Picking', 
      'Drivers', 
      'OperationalBlocks', 
      'Companies', 
      'PublicPickings', 
      'PickingCompletionRequests']);
    dispatch(logoutUser());
  }, [dispatch]);

  if (isUserLogout) {
    if(hasGroup(['CompanyAdministrator'])){
      return <Navigate to="/clients-login" />;
    } else {
      return <Navigate to="/login" />;
    }
  }

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};


export default withRouter(Logout);