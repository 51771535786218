import React, { Fragment, useState, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import * as moment from "moment";
import OrdersTableBase from "../../Components/Commons/OrdersTableBase";
import PickingStatus from "../Pickings/PickingStatus";
import { withTranslation, useTranslation } from 'react-i18next';

import 'react-toastify/dist/ReactToastify.css';


const RoutesTable = ({
  data,
  isLoading,
  isSuccess,
  error,
}) => {

  const { t, i18n } = useTranslation();

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".orderCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const toggle = useCallback(() => {
    
  });

  const handleOrderClick = useCallback((arg) => {
    const order = arg;
    setOrder({
      _id: order._id,
      orderId: order.orderId,
      customer: order.customer,
      product: order.product,
      orderDate: order.orderDate,
      ordertime: order.ordertime,
      amount: order.amount,
      payment: order.payment,
      status: order.status
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);

  const handleValidDate = date => {
    const date1 = moment(date).format("DD/MM");
    return date1;
  };

  const handleValidTime = (time) => {
    const updateTime = moment(time).format('HH:mm');
    return updateTime;
  };
  
  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Route Id"),
        accessor: "id",
        filterable: false,
        Cell: (cell) => {
          return <Link to={`${cell.row.original.id}`} className="fw-medium link-primary h5">{parseInt(cell.value)}</Link>;
        },
      },
      {
        Header: t("Driver"),
        Cell: (picking) => (
          <div className="d-flex">
            {picking.row.original.driver && (<div>
              <h5 className="fs-13 mb-0">{picking.row.original.driver.name}</h5>
              <p className="fs-12 mb-0 text-muted">{picking.row.original.driver.email}</p>
            </div>)}
          </div>
        ),
      },
      {
        Header: t("Orders"),
        filterable: false,
        Cell: (picking) => {
          return <h6 className="mb-0">
            {picking.row.original.picked_out_orders_count}
          </h6>;
        },
      },
      {
        Header: t("Created at"),
        accessor: "created_at",
        Cell: (picking) => (
          <>
            {handleValidDate(picking.row.original.created_at)},
            <small className="text-muted"> {handleValidTime(picking.row.original.created_at)}</small>
          </>
        ),
      },
      {
        Header: t("Updated at"),
        accessor: "updated_at",
        Cell: (picking) => (
          <>
            {handleValidDate(picking.row.original.updated_at)},
            <small className="text-muted"> {handleValidTime(picking.row.original.updated_at)}</small>
          </>
        ),
      },
      {
        Header: t('Picking Status'),
        Cell: (picking) => (
          <PickingStatus status={picking.row.original.status} status_text={picking.row.original.status_text} />
        )
      },
    ],
    [handleOrderClick, checkedAll]
  );

  return (
    <OrdersTableBase
      columns={columns}
      data={data}
      isLoading={isLoading}
      isSuccess={isSuccess}
      error={error}
      manualPagination={true}
      divClass="table-responsive table-card mb-1"
      tableClass="align-middle table-wrap"
      theadClass="table-light text-muted"
    />
  );
};

export default RoutesTable;

