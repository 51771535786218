import React from 'react';

/*
DELETED = 0
ENTERED = 1
WAITING_REVIEW = 2
COMPLETED = 3
*/

//Constants
const PickingStatus = ({ status, status_text}) => {
	if (status === 0) {
		return <div className={"badge badge-soft-danger"}>{status_text}</div>;
	} else if (status === 1) {
		return <div className={"badge bg-primary"}>{status_text}</div>;
	} else if (status === 2) {
		return <div className={"badge bg-warning"}>{status_text}</div>;
	} else if (status === 3) {
		return <div className={"badge bg-success"}>{status_text}</div>;
	}
};

export default PickingStatus;