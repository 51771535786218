import React, { useEffect, useState } from 'react';
import { Card, CardHeader, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner } from 'reactstrap';
import { useAsyncDebounce } from "react-table";
import RoutesTable from "./RoutesTable";
import { useNavigate, useLocation } from "react-router-dom";
import withRouter from "../../Components/Commons/withRouter";

import {
	useGetPublicPickingsQuery
} from '../../api/api'

//import images

const RoutesSearcher = (props) => {

	const [driverRut, setDriverRut] = useState(null);

	const location = useLocation();

	useEffect(() => {
		if(location.state && location.state.driverRut){
			setDriverRut(location.state.driverRut);
		}
	}, []);

	const { 
		data: driverRoutes,
		error,
		isFetching: driverRoutesLoading,
		isSuccess: driverRoutesSuccess
	} = useGetPublicPickingsQuery(
		driverRut ? driverRut : '',
		{
			refetchOnMountOrArgChange: true,
			skip: !driverRut,
		}
	);

	useEffect(() => {
		if (driverRoutesSuccess) {

		}
	}, [driverRoutesSuccess]);

	const onChange = useAsyncDebounce((value) => {
	setDriverRut(value);
  }, 200);

	document.title = "Login | Sistema de pedidos PROGISTICA";

	return (
		<React.Fragment>
			<div className="auth-page-content mt-5">
				<Container>
					<Row>
						<Col lg={12}>
							<Card>
								<CardHeader className="border-0">
									<Row className="justify-content-center mb-4">
										<Col lg={6}>
											<Row className="g-2 mb-4">
												<Col>
													<div className="position-relative mb-3">
														<Input
															name="driverRut"
															id="id-field"
															className="form-control form-control-lg bg-light border-light"
															placeholder="Search here..."
															type="text"
															value={driverRut || ""}
															validate={{
																required: { value: true },
															}}
															onChange={(e) => {
																onChange(e.target.value && e.target.value.length > 0 ? e.target.value : null);
															}}
														/>
													</div>
												</Col>
												<div className="col-auto">
													<button type="submit" className="btn btn-primary btn-lg waves-effect waves-light"><i className="mdi mdi-magnify me-1"></i> Search</button>
												</div>
											</Row>
										</Col>
										<Col lg={12}>
											<RoutesTable
												data={driverRoutes != null ? driverRoutes.results : []}
												isLoading={driverRoutesLoading}
												isSuccess={driverRoutesSuccess}
												error={error}
											/>
										</Col>
									</Row>
								</CardHeader>
							</Card>
						</Col>
					</Row>

				</Container>
			</div>
		</React.Fragment>
	);
};

export default withRouter(RoutesSearcher);