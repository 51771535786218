import getChartColorsArray from "../../Components/Commons/ChartsDynamicColor";
import React from 'react';

/*
CUSTOMER = 1
WAREHOUSE = 2
ASSIGNED = 3
ON_ROUTE = 4
DELIVERED = 5
SKIPPED = 6
REJECTED = 7
ERROR = 8
RETURNED = 9
WAREHOUSE_WITHOUT_MANAGEMENT = 10
WAREHOUSE_WITHOUT_CUSTOMER = 11
WAREHOUSE_REJECTED = 12
WAREHOUSE_WRONG_ADDRESS = 13
DELETED = 14
*/

//Constants
const OrderStatusIcon = ({status}) => {
	if (status === 1) {
		return 'ri-building-line';
	} else if (status === 2) {
		return 'ri-building-3-line';
	} else if (status === 3) {
		return 'ri-building-3-line';
	} else if (status === 4) {
		return 'ri-user-add-line';
	} else if (status === 5) {
		return 'ri-user-follow-line';
	} else if (status === 6) {
		return 'ri-skip-forward-line';
	} else if (status === 7) {
		return 'ri-user-unfollow-line';
	} else if (status === 8) {
		return 'ri-error-warning-line';
	} else if (status === 9) {
		return 'ri-user-shared-line';
	} else if (status === 10) {
		return 'ri-arrow-go-back-line';
	} else if (status === 11) {
		return 'ri-arrow-turn-back-line';
	} else if (status === 12) {
		return 'ri-arrow-turn-back-line';
	} else if (status === 13) {
		return 'ri-arrow-go-back-line';
	} else if (status === 14) {
		return 'ri-arrow-go-back-line';
	} else if (status === -4) {
		return 'ri-close-line';
	}
};

export default OrderStatusIcon;