import getChartColorsArray from "../../Components/Commons/ChartsDynamicColor";
import React from 'react';

/*
CUSTOMER = 1
WAREHOUSE = 2
ASSIGNED = 3
ON_ROUTE = 4
DELIVERED = 5
SKIPPED = 6
REJECTED = 7
ERROR = 8
RETURNED = 9
WAREHOUSE_WITHOUT_MANAGEMENT = 10
WAREHOUSE_WITHOUT_CUSTOMER = 11
WAREHOUSE_REJECTED = 12
WAREHOUSE_WRONG_ADDRESS = 13
DELETED = 14
*/

//Constants
const OrderStatusColor = ({ status}) => {
	if (status === 1) {
		return getChartColorsArray('["--vz-primary"]')[0];
	} else if (status === 2) {
		return getChartColorsArray('["--vz-secondary"]')[0];
	} else if (status === 3) {
		return getChartColorsArray('["--vz-light"]')[0];
	} else if (status === 4) {
		return getChartColorsArray('["--vz-secondary"]')[0];
	} else if (status === 5) {
		return getChartColorsArray('["--vz-success"]')[0];
	} else if (status === 6) {
		return getChartColorsArray('["--vz-warning"]')[0];
	} else if (status === 7) {
		return getChartColorsArray('["--vz-dark"]')[0];
	} else if (status === 8) {
		return getChartColorsArray('["--vz-danger"]')[0];
	} else if (status === 9) {
		return getChartColorsArray('["--vz-warning"]')[0];
	} else if (status === 10) {
		return getChartColorsArray('["--vz-danger"]')[0];
	} else if (status === 11) {
		return getChartColorsArray('["--vz-warning"]')[0];
	} else if (status === 12) {
		return getChartColorsArray('["--vz-dark"]')[0];
	} else if (status === 13) {
		return getChartColorsArray('["--vz-info"]')[0];
	} else if (status === 14) {
		return getChartColorsArray('["--vz-warning"]')[0];
	} else if (status === -4) {
		return getChartColorsArray('["--vz-danger"]')[0];
	}
};

export default OrderStatusColor;