import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Row, UncontrolledDropdown } from 'reactstrap';
import DeleteModal from "../../Components/Commons/DeleteModal";
import { ToastContainer } from 'react-toastify';

import Loader from "../../Components/Commons/Loader";
import Pagination from "../../Components/Commons/Pagination";

//import action
/*import {
	getProjectList as onGetProjectList,
	deleteProjectList as onDeleteProjectList,
} from "../../../slices/thunks";*/

import { 
	useGetPickingsQuery,
} from '../../api/api'

const List = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [deleteModal, setDeleteModal] = useState(false);

	const { 
		data: orderPickingsPages,
		error,
		isFetching: isOrderPickingsLoading,
		isSuccess: isOrderPickingsSuccess
	} = useGetPickingsQuery(currentPage, {
		refetchOnMountOrArgChange: true,
		skip: false,
	});

	useEffect(() => {
		if (orderPickingsPages && orderPickingsPages.count > 0) {
			setOrderImportList(orderPickingsPages.results);
		}
	}, [orderPickingsPages]);

	// delete
	const onClickData = (project) => {
		setProject(project);
		setDeleteModal(true);
	};

	const activebtn = (ele) => {
		if (ele.closest("button").classList.contains("active")) {
			ele.closest("button").classList.remove("active");
		} else {
			ele.closest("button").classList.add("active");
		}
	};

	return (
		<React.Fragment>
			<ToastContainer closeButton={false} />
			<DeleteModal
				show={deleteModal}
				onDeleteClick={() => handleDeleteProjectList()}
				onCloseClick={() => setDeleteModal(false)}
			/>
			<Row className="g-4 mb-3">
				<div className="col-sm-auto">
					<div>
						<Link to="#" className="btn btn-success"><i
							className="ri-add-line align-bottom me-1"></i> Add New</Link>
					</div>
				</div>
				<div className="col-sm-3 ms-auto">
					<div className="d-flex justify-content-sm-end gap-2">
						<div className="search-box ms-2 col-sm-7">
							<Input type="text" className="form-control" placeholder="Search..." />
							<i className="ri-search-line search-icon"></i>
						</div>

						<select className="form-control w-md" data-choices data-choices-search-false>
							<option value="All">All</option>
							<option value="Last 7 Days">Last 7 Days</option>
							<option value="Last 30 Days">Last 30 Days</option>
							<option value="Last Year">Last Year</option>
							<option value="This Month">This Month</option>
							<option value="Today">Today</option>
							<option value="Yesterday" defaultValue>Yesterday</option>
						</select>
					</div>
				</div>
			</Row>

			<div className="row">
				{isOrderPickingsLoading && <Loader/>}
				{!isOrderPickingsLoading &&
					(orderPickingsPages || []).map((item, index) => (
						<React.Fragment key={index}>
							<Col xxl={3} sm={6} className="project-card">
								<Card className="card-height-100">
									<CardBody>
										<div className={`p-3 mt-n3 mx-n3 bg-soft-${item.cardHeaderClass} rounded-top`}>
											<div className="d-flex align-items-center">
												<div className="flex-grow-1">
													<h5 className="mb-0 fs-14"><Link to="/apps-projects-overview" className="text-dark">{item.time}</Link></h5>
												</div>
											</div>
										</div>
										<div className="py-3">
											<Row className="gy-3">
												<Col xs={6}>
													<div>
														<p className="text-muted mb-1">Status</p>
														<div className={"fs-12 badge badge-soft-" + item.statusClass}>{item.status}</div>
													</div>
												</Col>
												<Col xs={6}>
													<div>
														<p className="text-muted mb-1">Billing</p>
														<h5 className="fs-14">{item.deadline}</h5>
													</div>
												</Col>
											</Row>
											<div className="d-flex align-items-center mt-3">
												<p className="text-muted mb-0 me-2">Team :</p>
												<div className="avatar-group">
													{item.subItem.map((item, key) => (
														<React.Fragment key={key}>
															{item.imgTeam ? <Link to="#" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Darline Williams">
																<div className="avatar-xxs">
																	<img src={item.imgTeam} alt="" className="rounded-circle img-fluid" />
																</div>
															</Link> : <Link to="#" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Donna Kline">
																<div className="avatar-xxs">
																	<div className={item.bgColor ? "avatar-title rounded-circle bg-" + item.bgColor : "avatar-title fs-16 rounded-circle bg-light border-dashed border text-primary"}>
																		{item.imgNumber}
																	</div>
																</div>
															</Link>}
														</React.Fragment>
													))}
												</div>
											</div>
										</div>
										
										<div className="mt-auto">
											<div className="d-flex mb-2">
												<div className="flex-grow-1">
													<div>Orders</div>
												</div>
												<div className="flex-shrink-0">
													<div><i className="ri-list-check align-bottom me-1 text-muted"></i> {item.number}</div>
												</div>
											</div>
											<div className="progress progress-sm animated-progess">
												<div className="progress-bar bg-success"
													role="progressbar" aria-valuenow="34" aria-valuemin="0" aria-valuemax="100"
													style={{ width: item.progressBar }}>
												</div>
											</div>
										</div>
									</CardBody>
								</Card>
							</Col>
						</React.Fragment>
					))
				}
			</div>
			{isOrderPickingsLoading && !isEmpty(isOrderPickingsLoading) && (
				<Pagination
				totalRows={orderPickingsPages.count}
				pageChangeHandler={setCurrentPage}
				rowsPerPage={25}
				/>
			)}
		</React.Fragment>
	);
};

export default List;