import React from 'react';
import { withTranslation } from 'react-i18next';
/*
DELETED = 0
ENTERED = 1
WAITING_REVIEW = 2
COMPLETED = 3
*/

//Constants
const OrderSetKind = ({collect_tickets, t, i18n }) => {
	if (collect_tickets && collect_tickets.length > 0) {
		return <div className={"badge bg-success"}>{t("With collect")}</div>;
	} else {
		return <div className={"badge bg-soft-success text-success"}></div>;
	}
};

export default withTranslation()(OrderSetKind);