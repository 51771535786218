import React from 'react';
import { Col, Row } from 'reactstrap';

const EmptyState = ({image, title, description}) => {
    return (
        <React.Fragment>
            <div className="text-center pt-4 mt-0">
                <div className="mt-2">
                    <h1 className="h4 fw-medium">{title}</h1>
                    <p className="text-muted pb-2 mb-4">{description}</p>
                </div>
                {image && (
                    <div className="">
                        <img src={image} alt="" className="error-basic-img move-animation" />
                    </div>
                )}
                
            </div>
        </React.Fragment>
    );
};

export default EmptyState;