import React, { Fragment, useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { withTranslation } from 'react-i18next';
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect
} from "react-table";

import { Table, Col, Button, Row, Input } from "reactstrap";
import { DefaultColumnFilter } from "./filters";
import { isEmpty } from "lodash";

import Loader from "./Loader";
import EmptyState from "./EmptyState";
import DeleteModal from "./DeleteModal";

import { useCreateOrderSetMutation } from '../../api/api'

const generateSortingIndicator = (column) => {
  return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
};

function TableStatus({
  isSuccess,
  isDataEmpty,
  tableClass,
  theadClass,
  trClass,
  thClass,
  getTableProps,
  getTableBodyProps,
  headerGroups,
  page,
  prepareRow,
  error,
  t,
  i18n
}) {
  if(isDataEmpty){
    return <EmptyState
      title={t("No records found")}
      description={t("Change the filters for better results")}
    />
  } else if(isSuccess){
    return <Table hover {...getTableProps()} className={tableClass}>
      <thead className={theadClass}>
        {headerGroups.map((headerGroup) => (
          <tr className={trClass} key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th key={column.id} className={thClass} {...column.getSortByToggleProps()}>
                {column.render("Header")}
                {generateSortingIndicator(column)}
                {/* <Filter column={column} /> */}
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {page.map((row) => {
          prepareRow(row);
          return (
            <Fragment key={row.getRowProps().key}>
              <tr className={trClass}>
                {row.cells.map((cell) => {
                  return (
                    <td key={cell.id} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            </Fragment>
          );
        })}
      </tbody>
    </Table>
  }
}

const OrdersTableBase = ({
  columns,
  data,
  isLoading,
  isSuccess,
  error,
  manualPagination,
  customPageSize,
  tableClass,
  theadClass,
  trClass,
  thClass,
  divClass,
  t,
  i18n
}) => {

  const [modal, setModal] = useState(false);
  const [order, setOrder] = useState([]);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      //dispatch(onDeleteOrder(element.value));
      setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
    });
    checkall.checked = false;
    setIsMultiDeleteButton(false);
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".orderCheckBox:checked");
    ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
      setDate(defaultdate());
    }
  }, [modal]);

  const handleOrderClick = useCallback((arg) => {
    const order = arg;
    setOrder({
      _id: order._id,
      orderId: order.orderId,
      customer: order.customer,
      product: order.product,
      orderDate: order.orderDate,
      ordertime: order.ordertime,
      amount: order.amount,
      payment: order.payment,
      status: order.status
    });

    setIsEdit(true);
    toggle();
  }, [toggle]);

  const handleDeleteOrder = () => {
    if (order) {
      //dispatch(onDeleteOrder(order._id));
      setDeleteModal(false);
    }
  };
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,

    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      manualPagination: manualPagination,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0, pageSize: customPageSize, selectedRowIds: 0,
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  return (
    <Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={deleteModalMulti}
        onDeleteClick={() => {
          deleteMultiple();
          setDeleteModalMulti(false);
        }}
        onCloseClick={() => setDeleteModalMulti(false)}
      />
      {isLoading && <Loader/>}
      {!isLoading && (
        <div className={divClass}>
          <TableStatus
            isSuccess={isSuccess}
            isDataEmpty={!data || isEmpty(data)}
            tableClass={tableClass}
            theadClass={theadClass}
            trClass={trClass}
            thClass={thClass}
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            page={page}
            prepareRow={prepareRow}
            error={error}
            t={t}
            i18n={i18n}
          />
        </div>
      )}

      {!manualPagination && data && !isEmpty(data) && (
        <Row className="justify-content-md-end justify-content-center align-items-center">
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                className="btn-sm"
                color="primary"
                onClick={previousPage}
                disabled={!canPreviousPage}
              >
                {"<"}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto d-none d-md-block">
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </Col>
          <Col className="col-md-auto">
            <Input
              type="number"
              className="form-control-sm"
              min={1}
              style={{ width: 70 }}
              max={pageOptions.length}
              defaultValue={pageIndex + 1}
              onChange={onChangeInInput}
            />
          </Col>

          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                className="btn-sm" 
                color="primary" 
                onClick={nextPage} 
                disabled={!canNextPage}
              >
                {">"}
              </Button>
            </div>
          </Col>
        </Row>
      )}
    </Fragment>
  );
};

OrdersTableBase.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(OrdersTableBase);