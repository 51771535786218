import React from 'react';

/*
DELETED = 0
ENTERED = 1
WAITING_REVIEW = 2
COMPLETED = 3
*/

//Constants
const OperationalBlockStatus = ({ status, status_text}) => {
	if (status === 0) {
		return <div className={"badge badge-soft-danger"}>{status_text}</div>;
	} else if (status === 1) {
		return <div className={"badge bg-primary"}>{status_text}</div>;
	}
};

export default OperationalBlockStatus;