import React, { useEffect, useState, useCallback, useRef } from "react";
import {
	Card,
	CardBody,
	Col,
	Container,
	CardHeader,
	Row,
	Table,
	Nav,
	NavItem,
	NavLink,
	Badge
} from "reactstrap";
import classnames from "classnames";
import PickingCompletionRequestStatus from "./PickingCompletionRequestStatus";
import * as moment from "moment";
import { Link, useParams } from "react-router-dom";
import BreadCrumb from "../../Components/Commons/BreadCrumb";
import Pagination from "../../Components/Commons/Pagination";
import Loader from "../../Components/Commons/Loader";
import { toast } from 'react-toastify';
import ErrorModal from "../../Components/Commons/ErrorModal";
import ConfirmModal from "../../Components/Commons/ConfirmModal";
import OrdersTable from "./OrdersTable";
import OrderSetsTable from "./OrderSetsTable";
import { hasGroup } from "../../api/session_helper";
import _ from 'lodash';
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import 'react-toastify/dist/ReactToastify.css';

import { 
	useGetPickingCompletionRequestByIdQuery,
	useGetPickingIssueOrdersQuery,
	useGetPickingIssueOrderSetsQuery,
	useAuthorizePickingMutation,
	useDenyPickingMutation,
 } from '../../api/api'

const PickingCompletionRequestDetails = () => {

	const { t } = useTranslation();

	let { id } = useParams();

	const navigate = useNavigate();

	const [authorizePickingModal, setAuthorizePickingModal] = useState(false);
	const [denyPickingModal, setDenyPickingModal] = useState(false);

	const [ordersCurrentPage, setOrdersCurrentPage] = useState(1);
	const [orderSetsCurrentPage, setOrderSetsCurrentPage] = useState(1);

	const [orderSetsActiveTab, setOrderSetsActiveTab] = useState(0);
	const [ordersActiveTab, setOrdersActiveTab] = useState(0);

	const [ordersIssue, setOrdersIssue] = useState(null);
	const [orderSetsIssue, setOrderSetsIssue] = useState(null);

	const [errorModal, setErrorModal] = useState(false);
	const [errorTitle, setErrorTitle] = useState(false);
	const [errorDescription, setErrorDescription] = useState(false);

	const { 
		data: request,
		error,
		isFetching: isPickingCompletionLoading,
		isSuccess: isPickingCompletionSuccess
	} = useGetPickingCompletionRequestByIdQuery(
		id,
		{
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

	const { 
		data: orders,
		error: ordersError,
		isFetching: isOrdersLoading,
		isSuccess: isOrdersSuccess
	} = useGetPickingIssueOrdersQuery(
		{
			id: ordersIssue ? ordersIssue.id : '',
			page: ordersCurrentPage
		},
		{
			refetchOnMountOrArgChange: true,
			skip: ordersIssue === null,
		}
	);

	const { 
		data: orderSets,
		error: orderSetsError,
		isFetching: isOrderSetsLoading,
		isSuccess: isOrderSetsSuccess
	} = useGetPickingIssueOrderSetsQuery(
		{
			id: orderSetsIssue ? orderSetsIssue.id : '',
			page: orderSetsCurrentPage
		},
		{
			refetchOnMountOrArgChange: true,
			skip: orderSetsIssue === null,
		}
	);

	useEffect(() => {
		if (request) {
			
			const orders_issues = request.picking_issues.filter((issue) => issue.orders_count > 0);
			if(orders_issues && orders_issues.length > 0){
				setOrdersIssue(orders_issues[0]);
			}

			const order_sets_issues = request.picking_issues.filter((issue) => issue.order_sets_count > 0);
			if(order_sets_issues && order_sets_issues.length > 0){
				setOrderSetsIssue(order_sets_issues[0]);
			}
		}
	}, [request]);

	useEffect(() => {
		if(error){
			setErrorTitle(t("Error when trying to obtain information of the route"));
			setErrorDescription(error);
			setErrorModal(true);
		}
	}, [error]);

	const handleValidDate = date => {
		const date1 = moment(date).format("DD/MM");
		return date1;
	};

	const handleValidTime = (time) => {
		const updateTime = moment(time).format('HH:mm');
		return updateTime;
	};

	const toggleOrderSetTab = (tab, issue) => {
		setOrderSetsCurrentPage(1);
		setOrderSetsIssue(issue);
		if (orderSetsActiveTab !== tab) {
			setOrderSetsActiveTab(tab);
		}
	};

	const toggleOrderTab = (tab, issue) => {
		setOrdersCurrentPage(1);
		setOrdersIssue(issue);
		if (ordersActiveTab !== tab) {
			setOrdersActiveTab(tab);
		}
	};

	const [authorizePickingIn, 
		{ error: authorizePickingError, 
			isLoading: authorizePickingLoading,
			isSuccess: authorizePickingSuccess,
			data: oauthorizePickingData
		}] = useAuthorizePickingMutation();
	
	useEffect(() => {
		if (authorizePickingSuccess) {
			toast.success(t(`Picking in authorized successfully!`), {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
					bodyClassName: "fs-24",
				}
			);
		}
		if (authorizePickingError) {
			setAuthorizePickingModal(false);
			setErrorTitle(t("Error when trying to authorize the picking!"));
			setErrorDescription(authorizePickingError);
			setErrorModal(true);
		}
	}, [authorizePickingSuccess, authorizePickingError]);

	const [denyPickingIn, 
		{ error: denyPickingError, 
			isLoading: denyPickingLoading,
			isSuccess: denyPickingSuccess,
			data: denyPickingData
		}] = useDenyPickingMutation();
	
	useEffect(() => {
		if (denyPickingSuccess) {
			setDenyPickingModal(false);
			toast.success(t(`Picking in denied successfully!`), {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
					bodyClassName: "fs-24",
				}
			);
		}
		if (denyPickingError) {
			setDenyPickingModal(false);
			setErrorTitle(t("Error when trying to deny the picking!"));
			setErrorDescription(denyPickingError);
			setErrorModal(true);
		}
	}, [denyPickingSuccess, denyPickingError]);

	const handleAuthorizePicking = () => {
		setAuthorizePickingModal(false);
		authorizePickingIn({id: id, pickingId: request.picking_id});
	};

	const handleDenyPicking = () => {
		denyPickingIn({id: id, pickingId: request.picking_id});
	};

	document.title = "Sistema de pedidos PROGISTICA";

	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<BreadCrumb title={t("Picking Completion Request Details")} pageTitle={t("Picking Completion Requests")} />
					<ErrorModal
						show={errorModal}
						onCloseClick={() => setErrorModal(false)}
						title={errorTitle}
						description={errorDescription}
					/>
					<ConfirmModal
						show={authorizePickingModal}
						onConfirmClick={() => handleAuthorizePicking()}
						onCloseClick={() => setAuthorizePickingModal(false)}
						title={t("Authorize picking")}
						question={t("Do you want to authorize the picking despite the indicated warnings?")}
						yesQuestion={t("Yes, authorize it")}
					/>
					<ConfirmModal
						show={denyPickingModal}
						onConfirmClick={() => handleDenyPicking()}
						onCloseClick={() => setDenyPickingModal(false)}
						title={t("Deny picking")}
						question={t("Do you want to deny the picking despite the indicated warnings?")}
						yesQuestion={t("Yes, deny it")}
					/>
					<Row>
						<Col xxl={3}>
							<Card id="picking-overview">
								<CardHeader className="align-items-center d-flex">
									<h4 className="card-title mb-0 flex-grow-1">{t("Information")}</h4>
								</CardHeader>
								<CardBody>
									{isPickingCompletionLoading && <Loader/>}
									{isPickingCompletionSuccess && (
										<div className="table-responsive table-card">
											<Table className="table mb-0">
												<tbody>
													<tr>
														<td className="fw-medium text-muted">{t("ID")}</td>
														<td className="overview-industryType">{parseInt(id)}</td>
													</tr>
													<tr>
														<td className="fw-medium text-muted">{t("Applicant")}</td>
														<td className="overview-industryType">
															<h6 className="mb-0">{request.user.first_name} {request.user.last_name}
															</h6>
														</td>
													</tr>
													<tr>
														<td className="fw-medium text-muted">{t("Status")}</td>
														<td className="overview-industryType">
															<PickingCompletionRequestStatus status={request.status} status_text={request.status_text} />
														</td>
													</tr>
													<tr>
														<td className="fw-medium text-muted">{t("Created at")}</td>
														<td className="overview-company_location">
															{handleValidDate(request.created_at)} {handleValidTime(request.created_at)}
														</td>
													</tr>
												</tbody>
											</Table>
										</div>
									)}
								</CardBody>
							</Card>

							{isPickingCompletionLoading && <Loader/>}
							{isPickingCompletionSuccess && request.status === 1 && (
								<Card id="picking-request-actions">
									<CardHeader className="align-items-center d-flex">
										<h4 className="card-title mb-0 flex-grow-1">{t("Actions")}</h4>
									</CardHeader>
									<CardBody>
										<div className="p-2 pt-1 pb-0">
											<button
												type="button"
												className="btn btn-success w-100"
												id="create-btn"
												onClick={() => { setAuthorizePickingModal(true); }}
											>
												<i className="ri-check-line align-bottom ms-1"></i>
												{t("Authorize")}
											</button>
										</div>
										<div className="p-2 pt-1 pb-0">
											<button
												type="button"
												className="btn btn-danger w-100"
												id="create-btn"
												onClick={() => { setDenyPickingModal(true); }}
											>
												<i className="ri-close-line align-bottom ms-1"></i>
												{t("Deny")}
											</button>
										</div>
									</CardBody>
								</Card>
							)}
						</Col>

						<Col xl={9}>
							{request && request.picking_issues.some((issue) => issue.orders_count > 0) && (
								<Card>
									<CardHeader className="align-items-center d-flex">
										<h4 className="card-title mb-0 flex-grow-1">{t("Issues on Boxes")}</h4>
									</CardHeader>
									<CardBody>
										<div>
											{isPickingCompletionSuccess && (
												<Nav
													className="nav-tabs nav-tabs-custom nav-success mb-3"
													role="tablist"
												>
													{request.picking_issues.filter((issue) => issue.orders_count > 0).map((issue, key) => (
														<NavItem key={key}>
															<NavLink
																className={classnames(
																	{ active: ordersActiveTab === key },
																	"fw-semibold"
																)}
																onClick={() => {
																	toggleOrderTab(key, issue);
																}}
																href="#"
															>
																<i className="ri-alert-fill me-1 align-bottom"></i>{" "}
																{issue.kind_text}{" "}
																<Badge color="warning" className="ms-1">{issue.orders_count}</Badge>
															</NavLink>
														</NavItem>
													))}
												</Nav>
											)}
											<OrdersTable
												data={orders != null ? orders.results : []}
												isLoading={isOrdersLoading}
												isSuccess={isOrdersSuccess}
												error={error}
											/>
											{orders && !isEmpty(orders.results) && (
												<Pagination
													totalRows={orders.count}
													pageChangeHandler={setOrdersCurrentPage}
													rowsPerPage={25}
												/>
											)}
										</div>
									</CardBody>
								</Card>
							)}
							
							{request && request.picking_issues.some((issue) => issue.order_sets_count > 0) && (
								<Card>
									<CardHeader className="align-items-center d-flex">
										<h4 className="card-title mb-0 flex-grow-1">{t("Issues on Orders")}</h4>
									</CardHeader>
									<CardBody>
										<div>
											{isPickingCompletionSuccess && (
												<Nav
													className="nav-tabs nav-tabs-custom nav-success"
													role="tablist"
												>
													{request.picking_issues.filter((issue) => issue.order_sets_count > 0).map((issue, key) => (
														<NavItem key={key}>
															<NavLink
																className={classnames(
																	{ active: orderSetsActiveTab === key },
																	"fw-semibold"
																)}
																onClick={() => {
																	toggleOrderSetTab(key);
																}}
																href="#"
															>
																<i className="ri-store-2-fill me-1 align-bottom"></i>{" "}
																{issue.kind_text}{" "}
																<Badge color="warning" className="ms-1">{issue.order_sets_count}</Badge>
															</NavLink>
														</NavItem>
													))}
												</Nav>
											)}
											<OrderSetsTable
												data={orderSets != null ? orderSets.results : []}
												isLoading={isOrderSetsLoading}
												isSuccess={isOrderSetsSuccess}
												error={orderSetsError}
											/>
											{orderSets && !isEmpty(orderSets.results) && (
												<Pagination
													totalRows={orderSets.count}
													pageChangeHandler={setOrderSetsCurrentPage}
													rowsPerPage={25}
												/>
											)}
										</div>
									</CardBody>
								</Card>
							)}

						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default PickingCompletionRequestDetails;