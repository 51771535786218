import React, { Fragment, useState, useMemo, useCallback } from "react";
import * as moment from "moment";
import { Link } from "react-router-dom";

import OrdersTableBase from "../../Components/Commons/OrdersTableBase";
import { hasGroup } from "../../api/session_helper";
import { useTranslation } from "react-i18next";

import 'react-toastify/dist/ReactToastify.css';

const CollectTicketsTable = ({
  data,
  isLoading,
  isSuccess,
  error,
  onMultipleSelected,
}) => {

  const { t } = useTranslation();

  const onClickDelete = (order) => {
    setOrder(order);
    setDeleteModal(true);
  };

  const handleOrderClick = useCallback((arg) => {
    const order = arg;
    setOrder({
      _id: order._id,
      orderId: order.orderId,
      customer: order.customer,
      product: order.product,
      orderDate: order.orderDate,
      ordertime: order.ordertime,
      amount: order.amount,
      payment: order.payment,
      status: order.status
    });

    setIsEdit(true);
  }, []);

  const handleValidDate = date => {
    const date1 = moment(date).format("DD/MM");
    return date1;
  };

  const handleValidTime = (time) => {
    const updateTime = moment(time).format('HH:mm');
    return updateTime;
  };
            
  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Collect Ticket Reference"),
        accessor: "reference",
        filterable: false,
        Cell: (cell) => {
          if(hasGroup(['Manager', 'OperationalManager', 'RouteManager', 'ReverseLogisticsOperator'])){
            return <Link to={`../collect-ticket/${cell.row.original.id}`} className="fw-medium link-primary h5">{cell.row.original.reference ?? cell.row.original.id}</Link>;
          } else {
            return <div className="fw-medium h5">{cell.row.original.reference ?? cell.row.original.id}</div>
          }
        },
      },
      {
        Header: t("Created at"),
        accessor: "created_at",
        Cell: (order) => (
          <>
            {handleValidDate(order.row.original.created_at)},
            <small className="text-muted"> {handleValidTime(order.row.original.created_at)}</small>
          </>
        ),
      },
      {
        Header: t("Emition date"),
        accessor: "emission_date",
        Cell: (order) => {
          if(order.row.original.emission_date){
            return handleValidDate(order.row.original.emission_date);
          }
          return '--';
        },
      },
      {
        Header: t("Products amount"),
        accessor: "quantity",
        filterable: false,
      },
      {
        Header: t("Action"),
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              {hasGroup(['Manager', 'OperationalManager', 'RouteManager', 'ReverseLogisticsOperator']) && (
                <li className="list-inline-item">
                  <Link 
                    to={`../collect-ticket/${cellProps.row.original.id}`}
                    className="fw-medium link-primary h5">
                    <i className="ri-eye-fill fs-16"></i>
                  </Link>
                </li>
              )}
            </ul>
          );
        },
      },
    ],
    [handleOrderClick]
  );

  return (
    <>
      <OrdersTableBase
        columns={columns}
        data={data}
        isLoading={isLoading}
        isSuccess={isSuccess}
        error={error}
        manualPagination={true}
        customPageSize={25}
        divClass="table-responsive table-card mb-1"
        tableClass="align-middle table-nowrap"
        theadClass="table-light text-muted"
      />
    </>
  );
};

export default CollectTicketsTable;

