import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import * as moment from "moment";
import Flatpickr from "react-flatpickr";
import ErrorModal from "../../Components/Commons/ErrorModal";
import ConfirmModal from "../../Components/Commons/ConfirmModal";
import { withTranslation } from 'react-i18next';
import OutOfBoxesTable from "../OutOfBoxes/OutOfBoxesTable";

import { isEmpty } from "lodash";
import { useAsyncDebounce } from "react-table";


import {
	Input,
	Card,
	CardBody,
	Col,
	CardHeader,
	Badge,
	Row
} from "reactstrap";

import Pagination from "../../Components/Commons/Pagination";

import { 
	useGetOutOfBoxesQuery
} from '../../api/api'

const List = ({t, i18n}) => {

	let navigate = useNavigate();

	const [currentPage, setCurrentPage] = useState(1);
	const [currentPageSize, setCurrentPageSize] = useState(25);
	const [orderSearch, setOrderSearch] = useState(null);

	const [creationStartDate, setCreationStartDate] = useState(null);
	const [creationEndDate, setCreationEndDate] = useState(null);
	const [billingStartDate, setBillingStartDate] = useState(null);
	const [billingEndDate, setBillingEndDate] = useState(null);
	const [campaign, setCampaign] = useState(null);
	const [zone, setZone] = useState(null);

	const [modal, setModal] = useState(false);
	
	const [importedInput, setImportedInput] = useState(null);
	const [ordersImportedModal, setOrdersImportedModal] = useState(false);

	const [errorModal, setErrorModal] = useState(false);
	const [errorTitle, setErrorTitle] = useState(false);
	const [errorDescription, setErrorDescription] = useState(false);

	function getHeaderClass(status) {
		if(status === 1){
			return "bg-soft-danger";
		} else if (status === 2) {
			return "bg-soft-primary";
		} else if (status === 3) {
			return "bg-soft-secondary";
		} else if (status === 4) {
			return "bg-soft-success";
		} else if (status === 5) {
			return "bg-soft-warning";
		}
	}

	const { 
		data: outOfBoxesPages,
		error,
		isFetching: isOutOfBoxesLoading,
		isSuccess: isOutOfBoxesSuccess
	} = useGetOutOfBoxesQuery(
		{
			page: currentPage,
			filter: orderSearch,
			billingStartDate: billingStartDate,
			billingEndDate: billingEndDate,
			creationStartDate: creationStartDate,
			creationEndDate: creationEndDate,
			campaign: campaign,
			zone: zone,
			page_size: currentPageSize
		}, {
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

	const handleValidBillingDate = date => {
		const date1 = moment(date).format("DD/MM");
		return date1;
	};
	
	const handleValidTime = (time) => {
		const updateTime = moment(time).format('HH:mm');
		return updateTime;
	};

	const handlePercentage = (partialValue, totalValue) => {
		if (totalValue > 0){
			return Math.round((partialValue / totalValue) * 350);
		} else{
			return 0;
		}
	};

	const handleSuccessImported = (importObject) => {
		setImportedInput(importObject);
		setOrdersImportedModal(true);
	};

	const handleNavigateToInput = () => {
		let data = importedInput;
		navigate(`../orders-import/${data.input_reference}`);
	};

	const handleImportedError = (error) => {
	let errorString;
				
		if(error.data instanceof Array){
			errorString = error.data.join(', ');
		} else if(error.error){
			errorString = error.error;
		} else {
			errorString = t("Unknown error")
		}
		setModal(false);
		setErrorTitle(t("Error when trying to import orders"));
		setErrorDescription(errorString);
		setErrorModal(true);
	};

	const onChange = useAsyncDebounce((value) => {
		setOrderSearch(value);
	}, 200);
	
	return (
		<React.Fragment>
			<ToastContainer closeButton={false} />
			<ErrorModal
				show={errorModal}
				onCloseClick={() => setErrorModal(false)}
				title={errorTitle}
				description={errorDescription}
			/>
			<ConfirmModal
				show={ordersImportedModal}
				onConfirmClick={handleNavigateToInput}
				onCloseClick={() => setOrdersImportedModal(false)}
				title={t("Order import created")}
				question={t("You will be redirected to the import detail so you can see the progress.")}
				yesQuestion="Ok"
			/>
			<Row>
				<Col lg={12}>
					<Card id="collectTicketsList">
						<CardHeader className="border-0">
							<Row className="align-items-center gy-3">
								<div className="col-sm">
									<h5 className="card-title mb-0">{t("Out of Boxes")}<Badge color="primary" className="ms-1">{outOfBoxesPages != null ? outOfBoxesPages.count : '...'}</Badge></h5>
								</div>
								<div className="col-sm-auto">
									{/*<div>
										<button
											type="button"
											className="btn btn-success add-btn"
											id="create-btn"
											onClick={() => { toggle(); }}
										>
											<i className="ri-download-line align-bottom me-1"></i> Create input picking
										</button>
									</div>*/}
								</div>
							</Row>
						</CardHeader>
						<CardBody className="pt-0">
							<div>
								<Row className="mb-3">
									<CardBody className="border border-dashed border-end-0 border-start-0">
										<form>
											<Row>
												<Col md={3}>
													<Input
														name="orderId"
														id="id-field"
														className="form-control"
														placeholder={t("Search")}
														type="text"
														onChange={(e) => {
															setCurrentPage(1);
															onChange(e.target.value);
														}}
													/>
												</Col>

												<Col md={3}>
													<Flatpickr
														className="form-control"
														id="datepicker-created-at"
														placeholder={t("Delivered at")}
														options={{
															altInput: true,
															altFormat: "F j, Y",
															mode: "range",
															dateFormat: "DD-MM-YYYY",
														}}
														onChange={(date) => {
															setCurrentPage(1);
															if(date[0]!== undefined){
																const date1 = moment(date[0]).format("DD-MM-YYYY");
																setCreationStartDate(date1);
															} else {
																setCreationStartDate(null);
															}
															if(date[1]!== undefined){
																const date1 = moment(date[1]).format("DD-MM-YYYY");
																setCreationEndDate(date1);
															} else {
																setCreationEndDate(null);
															}
														}}
													/>
												</Col>
									
												<Col md={3}>
													<Flatpickr
														className="form-control"
														id="datepicker-billing-date"
														placeholder={t("Billing Day")}
														options={{
															altInput: true,
															altFormat: "F j, Y",
															mode: "range",
															dateFormat: "DD-MM-YYYY",
														}}
														onChange={(date) => {
															setCurrentPage(1);
															if(date[0]!== undefined){
																const date1 = moment(date[0]).format("DD-MM-YYYY");
																setBillingStartDate(date1);
															} else {
																setBillingStartDate(null);
															}
															if(date[1]!== undefined){
																const date1 = moment(date[1]).format("DD-MM-YYYY");
																setBillingEndDate(date1);
															} else {
																setBillingEndDate(null);
															}
														}}
													/>
												</Col>

												<Col md={3}>
													<Input
														name="zoneId"
														id="id-field"
														className="form-control"
														placeholder={t("Zone Id")}
														type="text"
														validate={{
															required: { value: true },
														}}
														onChange={(e) => {
															setCurrentPage(1);
															setZone(e.target.value && e.target.value.length > 0 ? e.target.value : null);
															debugger;
														}}
													/>
												</Col>
									
											</Row>
											<Row className='mt-2'>

												
											
											</Row>
											<Row className='mt-2'>


												<Col md={3}>
													<Input
														name="campaignId"
														id="id-field"
														className="form-control"
														placeholder={t("Campaign Id")}
														type="text"
														validate={{
															required: { value: true },
														}}
														onChange={(e) => {
															setCurrentPage(1);
															setCampaign(e.target.value && e.target.value.length > 0 ? e.target.value : null);
														}}
													/>
												</Col>

											</Row>
										</form>
									</CardBody>
								</Row>
								<OutOfBoxesTable
									data={outOfBoxesPages != null ? outOfBoxesPages.results : []}
									isLoading={isOutOfBoxesLoading}
									isSuccess={isOutOfBoxesSuccess}
									error={error}
								/>
							</div>
							{outOfBoxesPages && !isEmpty(outOfBoxesPages.results) && (
								<Pagination
									totalRows={outOfBoxesPages.count}
									pageChangeHandler={setCurrentPage}
									rowsPerPage={25}
								/>
							)}
						</CardBody>
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	);
};

export default withTranslation()(List);