import React, { useEffect, useState, useCallback } from "react";
import {
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Form,
	Input,
	ModalHeader,
	Modal,
	ModalBody,
	Label,
	FormFeedback,
	Button,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem
} from "reactstrap";

import {
	useAsyncDebounce
} from "react-table";

import { useTranslation, initReactI18next } from "react-i18next";
import { hasGroup } from "../../api/session_helper";
import BreadCrumb from "../../Components/Commons/BreadCrumb";
import DeleteModal from "../../Components/Commons/DeleteModal";
import GenericModal from "../../Components/Commons/GenericModal";
import Loader from "../../Components/Commons/Loader";
import Pagination from "../../Components/Commons/Pagination";
import EmptyState from "../../Components/Commons/EmptyState";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { 
	useGetCompaniesQuery,
	useCreateCompanyMutation,
	useUpdateCompanyMutation,
	useDeleteCompanyMutation
} from '../../api/api'

import 'react-toastify/dist/ReactToastify.css';

const Companies = () => {

	const { t } = useTranslation();

	const [search, setSearch] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);

	const [company, setCompany] = useState(null);
	const [deleteModal, setDeleteModal] = useState(false);
	const [successModal, setSuccessModal] = useState(false);
	const [errorModal, setErrorModal] = useState(false);
	const [modal, setModal] = useState(false);

	const { 
		data: companiesPages,
		error,
		isFetching: areCompaniesLoading,
		isSuccess: areCompaniesSuccess
	} = useGetCompaniesQuery(
		{
			page: currentPage,
			filter: search
		}, 
		{
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

	useEffect(() => {
		if (companiesPages) {
			debugger;
		}
	}, [companiesPages]);

	const [createCompany, { 
		error: creationErrors, 
		isSuccess: isCreationSuccess
	}] = useCreateCompanyMutation();

	const [updateCompany, { 
		error: updateErrors, 
		isSuccess: isEditionSuccess
	}] = useUpdateCompanyMutation();
	
	const [deleteCompany, 
		{ error: deletionErrors, 
			isLoading: isDeletionLoading 
		}] = useDeleteCompanyMutation();

	const toggle = useCallback(() => {
		if (modal) {
			setModal(false);
			validation.resetForm();
		} else {
			setModal(true);
		}
	}, [modal]);

	useEffect(() => {
		const list = document.querySelectorAll(".team-list");
		const buttonGroups = document.querySelectorAll('.filter-button');
		for (let i = 0; i < buttonGroups.length; i++) {
			buttonGroups[i].addEventListener('click', onButtonGroupClick);
		}

		function onButtonGroupClick(event) {
			if (event.target.id === 'list-view-button' || event.target.parentElement.id === 'list-view-button') {
				document.getElementById("list-view-button").classList.add("active");
				document.getElementById("grid-view-button").classList.remove("active");
				list.forEach(function (el) {
					el.classList.add("list-view-filter");
					el.classList.remove("grid-view-filter");
				});

			} else {
				document.getElementById("grid-view-button").classList.add("active");
				document.getElementById("list-view-button").classList.remove("active");
				list.forEach(function (el) {
					el.classList.remove("list-view-filter");
					el.classList.add("grid-view-filter");
				});
			}
		}
	}, []);

	const handleCompanyEdit = useCallback((arg) => {
		const company = arg;
		setCompany(company);
		setModal(true);
		validation.resetForm();
	}, [toggle]);

	const handleCompanyRemove = useCallback((arg) => {
		setCompany(arg);
		setDeleteModal(true);
	}, [toggle]);

	useEffect(() => {
		if (isCreationSuccess || isEditionSuccess) {
			setSuccessModal(true);
		}
	}, [isCreationSuccess, isEditionSuccess]);

	useEffect(() => {
		if (creationErrors || updateErrors) {
			setErrorModal(true);
		}
	}, [creationErrors, updateErrors]);

	const onChange = useAsyncDebounce((value) => {
		setSearch(value);
	}, 200);

	// validation
	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: false,
		initialValues: {
			name: company ? company.name : '',
			description: company ? company.description : '',
		},
		validationSchema: Yup.object({
			name: Yup.string().required(t("Please Enter Company Name")),
			description: Yup.string().required(t("Please Enter Your designation")),
		}),
		onSubmit: (values) => {
			if (company) {
				const body = {
					name: values["name"],
					description: values["description"],
				};
				updateCompany({id: company.id, body: body});
			} else {
				const newCompany = {
					name: values["name"],
					description: values["description"],
				};
				createCompany(newCompany);
			}
			toggle();
		},
	});

	const handleDeleteCompany = () => {
		if (company) {
			deleteCompany(company.id);
			setDeleteModal(false);
			setCompany(null);
		}
	};

	document.title = t("WebService Data Replication NGPS");
	
	return (
		<React.Fragment>
			<DeleteModal
				show={deleteModal}
				onDeleteClick={() => handleDeleteCompany()}
				onCloseClick={() => setDeleteModal(false)}
			/>
			<GenericModal
				show={successModal}
				title={t("Company information saved")}
				message={t('All data was saved successfully')}
				okButtonText={'OK'}
				onOkClick={() => setSuccessModal(false)}
			/>
			<GenericModal
				show={errorModal}
				title={t("Company information not saved")}
				message={t('An error occurred while saving user data')}
				okButtonText={'OK'}
				onOkClick={() => setErrorModal(false)}
			/>
			<div className="page-content">
				<Container fluid className="container-fluid">
					<BreadCrumb title={t("Companies List")} pageTitle={t("Companies")} />
					<Row>
						<Col xxl={12}>
							<Card>

								<CardBody>
									<Form>
										<Row className="align-items-center g-3">

											<Col className="flex-grow-1">
												<div className="search-box">
													<Input
														name="orderId"
														id="id-field"
														className="form-control"
														placeholder={t("Search")}
														type="text"
														onChange={(e) => {
															setCurrentPage(1);
															onChange(e.target.value);
														}}
													/>
													<i className="ri-search-line search-icon"></i>
												</div>
											</Col>

											{hasGroup(['Manager', 'OperationalManager']) && (
												<Col className="col-lg-auto">
													<Button className="btn btn-primary createTask" type="button" onClick={() => setModal(true)}>
														<i className="ri-add-fill align-bottom" /> {t("New company")}
													</Button>
												</Col>
											)}
											
										</Row>
									</Form>
								</CardBody>
							</Card>

							<Row className="job-list-row h-100" id="companies-list">

								{areCompaniesLoading && <Loader/>}
								{areCompaniesSuccess && companiesPages.count > 0 && (
									companiesPages.results.map((item, key) => (
										<Col xxl={3} md={6} key={key}>
											<Card className="card companiesList-card">
												<CardBody>
													<Col className="team-settings">
														<Row>
															<UncontrolledDropdown direction='start' className="col text-end">
																<DropdownToggle tag="a" id="dropdownMenuLink2" role="button">
																	<i className="ri-more-fill fs-17"></i>
																</DropdownToggle>
																{hasGroup(['Manager', 'OperationalManager']) && (
																	<DropdownMenu>
																		<DropdownItem className="dropdown-item edit-list" onClick={() => handleCompanyEdit(item)}>
																			<i className="ri-pencil-line me-2 align-bottom text-muted"></i>{t("Edit")}
																		</DropdownItem>
																		<DropdownItem className="dropdown-item remove-list" onClick={() => handleCompanyRemove(item)}>
																			<i className="ri-delete-bin-5-line me-2 align-bottom text-muted"></i>{t("Remove")}
																		</DropdownItem>
																	</DropdownMenu>
																)}
															</UncontrolledDropdown>
														</Row>
													</Col>
													<div className="avatar-sm mx-auto">
														<div className="bg-light rounded avatar-title fs-36">
															<i className="ri-global-line text-primary"></i>
														</div>
													</div>
													<div className="text-center">
														<h5 className="mt-3 company-name">{item.name}</h5>
														<div className="company-desc mb-3">
															{item.description}
														</div>
													</div>
												</CardBody>
											</Card>
										</Col>
									))
								)}

								{!areCompaniesLoading && companiesPages && companiesPages.count == 0  && (
									<EmptyState
										title={t("No records found")}
										description={t("Change the filters for better results")}
									/>
								)}
							</Row>

							{companiesPages && !isEmpty(companiesPages.results) && (
								<Pagination
									totalRows={companiesPages.count}
									pageChangeHandler={setCurrentPage}
									rowsPerPage={25}
								/>
							)}

						</Col>

						<div className="modal fade" id="addcompany" tabIndex="-1" aria-hidden="true">
							<div className="modal-dialog modal-dialog-centered">
								<Modal isOpen={modal} toggle={toggle} centered>
									<ModalHeader toggle={toggle}>{company ? t("Edit Company") : t("Create Company")}</ModalHeader>
									<ModalBody>
										<Form onSubmit={validation.handleSubmit}>
											<Row>
												<Col lg={12}>

													<div className="mb-3">
														<Label htmlFor="name" className="form-label">{t("Name")}</Label>
														<Input type="text" className="form-control" id="name" placeholder={t("Name")}
															name='name'
															validate={{
																	required: { value: true },
															}}
															onChange={validation.handleChange}
															onBlur={validation.handleBlur}
															value={validation.values.name || ""}
															invalid={(validation.errors.name) || (creationErrors && creationErrors.name) || (updateErrors && updateErrors.name) ? true : false}
														/>
														{validation.errors.name ? (
															<FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
														) : null}
														<FormFeedback type="invalid">{creationErrors && creationErrors.name ? (<div>{creationErrors.name}</div>) : null}</FormFeedback>
														<FormFeedback type="invalid">{updateErrors && updateErrors.name ? (<div>{updateErrors.name}</div>) : null}</FormFeedback>
													</div>
												</Col>
												<Col lg={12}>
													<div className="mb-3">
														<Label htmlFor="description" className="form-label">{t("Description")}</Label>
														<Input type="text" className="form-control" id="description" placeholder={t("Description")} name='description'
															validate={{
																	required: { value: true },
															}}
															onChange={validation.handleChange}
															onBlur={validation.handleBlur}
															value={validation.values.description || ""}
															invalid={(validation.errors.description) || (creationErrors && creationErrors.description) || (updateErrors && updateErrors.description) ? true : false}
														/>
														{validation.errors.description ? (
															<FormFeedback type="invalid">{validation.errors.description}</FormFeedback>
														) : null}
														<FormFeedback type="invalid">{creationErrors && creationErrors.description ? (<div>{creationErrors.description}</div>) : null}</FormFeedback>
														<FormFeedback type="invalid">{updateErrors && updateErrors.description ? (<div>{updateErrors.description}</div>) : null}</FormFeedback>
													</div>
												</Col>
												<Col lg={12}>
													<div className="hstack gap-2 justify-content-end">
														<button type="button" className="btn btn-light" onClick={() => toggle()}>{t("Close")}</button>
														<button type="submit" className="btn btn-success" id="addNewMember">{!company ? t("Create Company") : t("Save")}</button>
													</div>
												</Col>
											</Row>
										</Form>
									</ModalBody>
								</Modal>
							</div>
						</div>

					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Companies;


