import React from 'react';
import BreadCrumb from '../../Components/Commons/BreadCrumb';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import List from './List';

const OrderImports = ({t, i18n}) => {

    document.title = "PROGISTICA | Orders Platform";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t("Collect Tickets")} pageTitle={t("Collect Tickets List")} />
                    <List />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(OrderImports);