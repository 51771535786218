import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Scrollspy from "react-scrollspy";
import {
	Collapse,
	Container,
	NavbarToggler,
	NavLink,
} from "reactstrap";
import LogoDark from "../../images/logo-dark.png";
import LogoLight from "../../images/logo-light.png";

const Navbar = () => {

	const [isOpenMenu, setisOpenMenu] = useState(false);
	const [navClass, setnavClass] = useState("");

	const toggle = () => setisOpenMenu(!isOpenMenu);

	useEffect(() => {
		window.addEventListener("scroll", scrollNavigation, true);
	});

	const scrollNavigation = () => {
		var scrollup = document.documentElement.scrollTop;
		if (scrollup > 50) {
			setnavClass(" is-sticky");
		} else {
			setnavClass("");
		}
	};

	return (
		<React.Fragment>
			<nav
				className={
					"navbar navbar-expand-lg navbar-landing fixed-top job-navbar" +
					navClass
				}
				id="navbar"
			>
				<Container fluid className="custom-container">
					<Link className="navbar-brand" to="/index">
						<img
							src={LogoDark}
							className="card-logo card-logo-dark"
							alt="logo dark"
							height="45"
						/>
						<img
							src={LogoLight}
							className="card-logo card-logo-light"
							alt="logo light"
							height="45"
						/>
					</Link>
					<NavbarToggler
						onClick={toggle}
						className="navbar-toggler py-0 fs-20 text-body"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<i className="mdi mdi-menu"></i>
					</NavbarToggler>

					<Collapse className="navbar-collapse" id="navbarSupportedContent">
						<Scrollspy
							offset={-18}
							items={[
								"hero",
								"process",
								"categories",
								"findJob",
								"candidates",
								"blog",
							]}
							currentClassName="active"
							className="navbar-nav mx-auto mt-2 mt-lg-0"
							id="navbar-example"
						>
							<li className="nav-item-disabled pl-4 ml-4">
								<NavLink className="fs-16 " href="https://transchilespa.cl">
									Inicio
								</NavLink>
							</li>
							<li className="nav-item">
								<NavLink className="fs-16" href="https://transchilespa.cl/contacto">
									Contacto
								</NavLink>
							</li>
							<li className="nav-item" >
								<NavLink className="fs-16 active">
									Tracking
								</NavLink>
							</li>
						</Scrollspy>

						<div>
							<Link to="https://transchile.optiroute.cl/clients-login" className="btn rounded-pill btn-danger fw-semibold">
								<i className="ri-user-3-line align-bottom me-1"></i>
								ACCESSO CLIENTES
							</Link>
						</div>
					</Collapse>
				</Container>
			</nav>
		</React.Fragment>
	);
};

export default Navbar;
