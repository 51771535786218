import React from 'react';

/*
CUSTOMER = 1
WAREHOUSE = 2
ASSIGNED = 3
ON_ROUTE = 4
DELIVERED = 5
SKIPPED = 6
REJECTED = 7
ERROR = 8
RETURNED = 9
WAREHOUSE_WITHOUT_MANAGEMENT = 10
WAREHOUSE_WITHOUT_CUSTOMER = 11
WAREHOUSE_REJECTED = 12
WAREHOUSE_WRONG_ADDRESS = 13
DELETED = 14
*/

//Constants
const OrderStatusPublic = ({ status, status_text}) => {
	if (status === 1) {
		return <div className={"badge bg-primary"}>{status_text}</div>;
	} else if (status === 2) {
		return <div className={"badge bg-secondary"}>{status_text}</div>;
	} else if (status === 4) {
		return <div className={"badge bg-info"}>{status_text}</div>;
	} else if (status === 5) {
		return <div className={"badge bg-success"}>{status_text}</div>;
	} else if (status === 6) {
		return <div className={"badge bg-warning"}>{status_text}</div>;
	} else if (status === 7) {
		return <div className={"badge bg-warning"}>{status_text}</div>;
	} else if (status === 8) {
		return <div className={"badge bg-warning"}>{status_text}</div>;
	} else if (status === 9) {
		return <div className={"badge bg-danger"}>{status_text}</div>;
	} else if (status === 10) {
		return <div className={"badge bg-danger"}>{status_text}</div>;
	} else if (status === 11) {
		return <div className={"badge bg-danger"}>{status_text}</div>;
	} else if (status === 12) {
		return <div className={"badge bg-danger"}>{status_text}</div>;
	} else if (status === 13) {
		return <div className={"badge bg-warning"}>{status_text}</div>;
	} else if (status === 14) {
		return <div className={"badge bg-warning"}>{status_text}</div>;
	} else if (status === -4) {
		return <div className={"badge badge-soft-danger"}>{status_text}</div>;
	}
};

export default OrderStatusPublic;