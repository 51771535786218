import React, { useState, useCallback, useEffect } from "react";

import {
	Card,
	CardBody,
	Col,
	Input,
	Container,
	Form,
	Row,
} from "reactstrap";

import Loader from "../../Components/Commons/Loader";
import { useParams, useNavigate } from "react-router-dom";

import * as moment from "moment";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import notFoundImage from "../../images/not_found.png";

import OrderStatusPublic from "../OrderSets/OrderStatusPublic";
import OrderStatusIcon from "../OrderSets/OrderStatusIcon";
import EmptyState from "../../Components/Commons/EmptyState";
import Footer from "./Footer";
import CustomerOrderSetsTable from "../OrderSets/CustomerOrderSetsTable";
import Masonry from "react-masonry-component";

import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";

SwiperCore.use([FreeMode, Navigation, Thumbs]);

import Navbar from "./Navbar";

import {
	useGetPublicOrderSetsQuery
} from '../../api/api';

const CustomerOrderSetsSearch = () => {

	document.title = "Seguimiento en línea de pedidos PROGISTICA";
	
	const navigate = useNavigate();

	const [currentPage, setCurrentPage] = useState(1);
	const [campaign, setCampaign] = useState(null);

	let { id } = useParams();

	const [search, setSearch] = useState(id);

	const { 
		data: orderSets,
		error,
		isFetching: orderSetsLoading,
		isSuccess: orderSetsSuccess
	} = useGetPublicOrderSetsQuery(
		{ 
			page: currentPage,
			campaign: campaign,
			customer_reference: id,
			page_size: 10
		},
		{
			refetchOnMountOrArgChange: true,
			skip: !id,
		}
	);

	useEffect(() => {
		if(orderSets){
			debugger;
		}
	}, [orderSets]);

	const handleValidDate = date => {
    const date1 = moment(date).format("DD/MM");
    return date1;
  };

	const handleValidTime = (time) => {
		const updateTime = moment(time).format('HH:mm');
		return updateTime;
	};
	
	document.title = "Tracking de pedidos PROGISTICA";

	window.onscroll = function () {
		scrollFunction();
	};

	const scrollFunction = () => {
		const element = document.getElementById("back-to-top");
		if (element) {
			if (
				document.body.scrollTop > 100 ||
				document.documentElement.scrollTop > 100
			) {
				element.style.display = "block";
			} else {
				element.style.display = "none";
			}
		}
	};

	const searchPressed = useCallback((value) => {
		navigate(`../tracking/${search}`);
	});

	const onSearchChange = useCallback((value) => {
		setSearch(value);
	});

	const toTop = () => {
		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	};

	return (
		<React.Fragment>
			<div className="layout-wrapper landing tracking-details bg-light">
				<Navbar />
				<div className="bg-overlay bg-overlay-pattern"></div>
				<Container className="mb-4 pb-4">
					<Row className="section tracking-hero-section pb-4">
						<Col lg={12}>
							<Card>
								<CardBody>
									<Row className="gx-lg-5 p-4">
										<Form action="#" className="p-2">
											<Row className="justify-content-center">
												<Col lg={6}>
													<Row className="g-2">
														<Col>
															<div className="position-relative mb-4">
																<Input 
																	type="search"
																	className="form-control form-control-lg bg-light border-light"
																	placeholder="Número de consultora"
																	defaultValue={id}
																	onChange={(e) => onSearchChange(e.target.value)} 
																/>
															</div>
														</Col>
														<div className="col-auto">
															<button
																type="submit"
																className="btn btn-success btn-lg waves-effect waves-light"
																disabled={orderSetsLoading}
																onClick={(e) => {
																	searchPressed(e.target.value);
																}}>
																	<i className="mdi mdi-magnify me-1"></i>
																	Buscar
																</button>
														</div>
													</Row>
												</Col>
											</Row>
										</Form>
										<Col xl={12}>
											{orderSetsLoading && <Loader/>}
											{orderSetsSuccess && (orderSets != null && orderSets.results.length > 0) && (
												<>
													<div className="text-center mb-5">
														<h2 className="mb-3 ff-secondary fw-bold lh-base">
															A continuación tus últimos pedidos
														</h2>
														<p className="text-muted">
															*Pedidos facturados hace 20 días
														</p>
													</div>
													<CustomerOrderSetsTable
														data={orderSets != null ? orderSets.results : []}
														isLoading={orderSetsLoading}
														isSuccess={orderSetsSuccess}
														error={error}
													/>
												</>
												
											)}
											{(error || (orderSets != null && orderSets.results.length == 0)) && (
												<EmptyState
													image={notFoundImage}
													title={"No se encontraron pedidos para la consultora"}
													description={"Verifica el número de consultora o intenta nuevamente en otra ocasión"}
												/>
											)}
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
				<Footer />

				<button
					onClick={() => toTop()}
					className="btn btn-danger btn-icon landing-back-top"
					id="back-to-top"
				>
					<i className="ri-arrow-up-line"></i>
				</button>
			</div>
		</React.Fragment>
	);
};

export default CustomerOrderSetsSearch;