import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
	Input,
	Col,
	Modal,
	ModalHeader,
	ModalBody,
	Label
} from "reactstrap";
import { Link, useParams, useNavigate } from "react-router-dom";

const MultipleSelectModal = ({ isOpen, onConfirmClick, onCloseClick, defaultItemsString}) => {
  
  const ref = useRef(null);

  const { t } = useTranslation();

  const [items, setItems] = useState([]);

  useEffect(() => {
		if (defaultItemsString) {
			const array = defaultItemsString.split(' ');
      const array2 = array.filter((item) => item !== "");

      setItems(array2);
		}
	}, [defaultItemsString]);

  const addOrderCode = (orderReference) => {
		const newOrders =  [...items, orderReference];
    setItems(newOrders);
    ref.current.value = "";
	};

  const removeOrderCode = (orderReference) => {
		const arr = items.filter((item) => item !== orderReference);
    setItems(arr);
	};

  return (
    <Modal fade={true} isOpen={isOpen} toggle={onCloseClick} centered={true}>
      <ModalHeader className="bg-light p-3" >
        {t("Select multiple orders")}
      </ModalHeader>
      <ModalBody className="py-3 px-5">
        <div className="mb-3">
          <Label
            htmlFor="choices-publish-status-input"
            className="form-label"
          >
            {t("Ingresa el código del pedido")}
          </Label>

          <Col md={12}>
            <div className="hstack gap-3 px-3 mx-n3">

              <input
                autoFocus
                ref={ref}
                className="form-control me-auto"
                type="text"
                placeholder={t("Enter order reference")}
                onKeyUp={e => {
                  if (e.key === 'Enter') {
                    const value = ref.current.value.replace(/\s/g, "");
                    addOrderCode(value);
                  }
                }}
              />

              <button 
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  const value = ref.current.value.replace(/\s/g, "");
                  addOrderCode(value);
                }}>
                  <i className="ri-add-fill me-1 align-bottom"></i>
              </button>
            </div>
          </Col>
        </div>

        <ul className="list-group" style={{ overflowY: "scroll", maxHeight: "400px"}}>
          {(items.map((orderId, key) => (
            <li className="list-group-item d-flex justify-content-between align-items-center" key={key}>
              {orderId}
              <Link
                to="#"
                className="text-danger d-inline-block remove-item-btn"
                onClick={() => {
                  removeOrderCode(orderId);
                }}
              >
                <i className="ri-delete-bin-5-fill fs-16"></i>
              </Link>
            </li>
          )))}
          
        </ul>
        
        <div className="d-flex gap-2 mt-4 mb-2">
          
          <button
            type="button"
            className="btn w-sm btn-success "
            id="confirm-action"
            onClick={() => {
              onConfirmClick(items);
              onCloseClick();
            }}
          >
            {t("Add")}
          </button>

          <button
            type="button"
            className="btn w-sm btn-light"
            data-bs-dismiss="modal"
            onClick={() => (
              onCloseClick()
            )}
          >
            {t("Close")}
          </button>

        </div>
      </ModalBody>
    </Modal>
  );
};

export default MultipleSelectModal;