import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import LogoLight from "../../images/logo-light.png";

const Footer = () => {
	return (
		<React.Fragment>
			<footer className="custom-footer bg-dark py-5 position-relative">
				<Container>
					<Row>
						<Col className="col-lg-4 mt-4">
							<div>
								<div>
									<img src={LogoLight} alt="logo light" height="17" />
								</div>
								<div className="mt-4 fs-15">
									<p>Plataforma de seguimiento PROGISTICA</p>
									<p>Transporte, logística y distribución</p>
									<p>Nuestro equipo de conductores cuenta con una vasta experiencia en distribución a lo largo de todo el país, siendo un complemento clave para agilizar procesos de comercialización y entrega.</p>
									{/*<ul className="list-inline mb-0 footer-social-link">
										<li className="list-inline-item">
											<Link to="#!" className="avatar-xs d-block">
												<div className="avatar-title rounded-circle">
													<i className="ri-facebook-fill"></i>
												</div>
											</Link>
										</li>
										<li className="list-inline-item">
											<Link to="#!" className="avatar-xs d-block">
												<div className="avatar-title rounded-circle">
													<i className="ri-github-fill"></i>
												</div>
											</Link>
										</li>
										<li className="list-inline-item">
											<Link to="#!" className="avatar-xs d-block">
												<div className="avatar-title rounded-circle">
													<i className="ri-linkedin-fill"></i>
												</div>
											</Link>
										</li>
										<li className="list-inline-item">
											<Link to="#!" className="avatar-xs d-block">
												<div className="avatar-title rounded-circle">
													<i className="ri-google-fill"></i>
												</div>
											</Link>
										</li>
										<li className="list-inline-item">
											<Link to="#!" className="avatar-xs d-block">
												<div className="avatar-title rounded-circle">
													<i className="ri-dribbble-line"></i>
												</div>
											</Link>
										</li>
									</ul>*/}
								</div>
							</div>
						</Col>

						<Col className="col-lg-7 ms-lg-auto">
							<Row>
								<Col className="col-sm-12 mt-4">
									<h5 className="text-white mb-0">Información</h5>
									<div className="text-muted mt-3">
										<ul className="list-unstyled ff-secondary footer-list fs-16">
											<li>
												<i className="ri-time-line me-1 align-bottom"></i>{" "}
												Lunes a viernes 9:00 a 18:30 hrs
											</li>
											<li>
												<i className="ri-map-pin-line me-1 align-bottom"></i>{" "}
												Av. Los vientos 20090, módulo A, Pudahuel
											</li>
											<li>
												<i className="ri-phone-line me-1 align-bottom"></i>{" "}
												+56 2 32626181
											</li>
											<li>
												<i className="ri-mail-line me-1 align-bottom"></i>{" "}
												admin@transchilespa.cl
											</li>
										</ul>
									</div>
								</Col>
								
							</Row>
						</Col>
					</Row>

					<Row className="text-center text-sm-start align-items-center mt-5">
						<Col className="col-sm-6">
							<div>
								<p className="copy-rights mb-0">
									{new Date().getFullYear()} ©
									PROGISTICA SPA
								</p>
							</div>
						</Col>
						{/*<Col className="col-sm-6">
							<div className="text-sm-end mt-3 mt-sm-0">
								<ul className="list-inline mb-0 footer-list gap-4 fs-15">
									<li className="list-inline-item">
										<Link to="/pages-privacy-policy">Privacy Policy</Link>
									</li>
									<li className="list-inline-item">
										<Link to="/pages-term-conditions">
											Terms & Conditions
										</Link>
									</li>
									<li className="list-inline-item">
										<Link to="/pages-privacy-policy">Security</Link>
									</li>
								</ul>
							</div>
						</Col>*/}
					</Row>
				</Container>
			</footer>
		</React.Fragment>
	);
};

export default Footer;
