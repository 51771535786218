import React from 'react';

/*
DELETED = 0
ENTERED = 1
*/

//Constants
const OrderEventStatus = ({ status, status_text}) => {
	if (status === 0) {
		return <div className={"fs-20 badge bg-danger"}>{status_text}</div>;
	} else if (status === 1) {
		return <div className={"fs-20 badge bg-primary"}>{status_text}</div>;
	}
};

export default OrderEventStatus;