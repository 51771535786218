import React from "react";
import { Navigate } from "react-router-dom";

import Login from "../Pages/Authentication/Login";
import CoverSignIn from "../Pages/AuthenticationInner/Login/CoverSignIn";
import Logout from "../Pages/Authentication/Logout";
import RoutesSearcher from "../Pages/Routes/RoutesSearcher";

//import Dashboard from "../Pages/Dashboard";
import Orders from "../Pages/OrderSets/index";
import OrderDetail from "../Pages/OrderSets/OrderDetail";
import Users from "../Pages/Users/index";
import UserProfile from "../Pages/Users/UserProfile";
import OrderImports from "../Pages/OrderImports/index";
import OrdersImportDetails from "../Pages/OrderImports/OrdersImportDetails";
import PackageDownload from "../Pages/PackageDownload/List/index";
import BasicLockScreen from '../Pages/AuthenticationInner/LockScreen/BasicLockScr';

const authProtectedRoutes = [
  //{ path: "/index", component: <Dashboard /> },
  //{ path: "/orders", component: <Orders /> },
  //{ path: "/order-detail", component: <OrderDetail /> },
  { path: "/users", component: <Users /> },
  //{ path: "/orders-import", component: <OrderImports /> },
  //{ path: "/orders-import-details", component: <OrdersImportDetails /> },
  { path: "/download", component: <PackageDownload /> },
  { path: "/user-profile", component: <UserProfile /> },
  { path: "/auth-lockscreen", component: <BasicLockScreen />},
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: <Navigate to="/order-sets" /> },
  { path: "*", component: <Navigate to="/order-sets" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/clients-login", component: <CoverSignIn /> },
];

export { authProtectedRoutes, publicRoutes };