import React, { Fragment, useState, useMemo, useCallback } from "react";
import { withTranslation } from 'react-i18next';
import * as moment from "moment";
import {
  Input,
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
  ModalHeader,
  Modal,
  ModalBody,
  Row,
  Form,
  Label,
  FormFeedback
} from "react-table";

import { Link } from "react-router-dom";
import { Table, Col, Button } from "reactstrap";
import { DefaultColumnFilter } from "../../Components/Commons/filters";
import { isEmpty } from "lodash";
import Flatpickr from "react-flatpickr";
import * as Yup from "yup";
import { useFormik } from "formik";

import Loader from "../../Components/Commons/Loader";
import Pagination from "../../Components/Commons/Pagination";
import EmptyState from "../../Components/Commons/EmptyState";
import DeleteModal from "../../Components/Commons/DeleteModal";

import { useCreateOrderSetMutation } from '../../api/api'

const generateSortingIndicator = (column) => {
  return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
};

function TableStatus({
  isSuccess,
  isDataEmpty,
  tableClass,
  theadClass,
  trClass,
  thClass,
  getTableProps,
  getTableBodyProps,
  headerGroups,
  page,
  prepareRow,
  error,
  t
}) {
  if(isDataEmpty){
    return <EmptyState
      title={t("No records found")}
      description={t("Change the filters for better results")}
    />
  } else if(isSuccess){
    return <Table hover {...getTableProps()} className={tableClass}>
      <thead className={theadClass}>
        {headerGroups.map((headerGroup) => (
          <tr className={trClass} key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th key={column.id} className={thClass} {...column.getSortByToggleProps()}>
                {column.render("Header")}
                {generateSortingIndicator(column)}
                {/* <Filter column={column} /> */}
              </th>
            ))}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {page.map((row) => {
          prepareRow(row);
          return (
            <Fragment key={row.getRowProps().key}>
              <tr>
                {row.cells.map((cell) => {
                  return (
                    <td key={cell.id} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            </Fragment>
          );
        })}
      </tbody>
    </Table>
  }
}

const handleValidTime = (time) => {
  const updateTime = moment(time).format('HH:mm');
  return updateTime;
};

const OperationalBlockTable = ({
  data,
  setCurrentPage,
  isLoading,
  isSuccess,
  error,
  totalDataLength,
  customPageSize,
  tableClass,
  theadClass,
  trClass,
  thClass,
  divClass,
  t, i18n
}) => {

  const handleValidDate = date => {
    const date1 = moment(date).format("DD/MM");
    return date1;
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Billing Day"),
        accessor: "billing_date",
        Cell: (cell) => {
          return <Link to={`${cell.row.original.id}`} className="fw-medium link-primary h5">{handleValidDate(cell.row.original.billing_date)}</Link>;
        },
      },
      {
        Header: t("Total Orders"),
        filterable: false,
        Cell: (cell) => {
          return cell.row.original.company_total_orders_count;
        },
      },
      {
        Header: t("On Warehouse Orders"),
        filterable: false,
        Cell: (cell) => {
          return cell.row.original.warehouse_orders_count;
        },
      },
      {
        Header: t("Delivered Orders"),
        filterable: false,
        Cell: (cell) => {
          return cell.row.original.warehouse_orders_count;
        },
      },
      /*{
        Header: t("Pickings"),
        filterable: false,
        Cell: (cell) => {
          return cell.row.original.pickings_count;
        },
      },*/
      {
        Header: t("Imports"),
        filterable: false,
        Cell: (cell) => {
          return cell.row.original.import_inputs_count;
        },
      },
      {
        Header: t("Created at"),
        accessor: "created_at",
        Cell: (order) => (
          <>
            {handleValidDate(order.row.original.created_at)},
            <small className="text-muted"> {handleValidTime(order.row.original.created_at)}</small>
          </>
        ),
      },
      {
        Header: t("Action"),
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <Link to={`${cellProps.row.original.id}`} className="fw-medium link-primary"><i className="ri-eye-fill fs-16"></i></Link>                
              </li>
            </ul>
          );
        },
      },
    ]
  );
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    //canPreviousPage,
    //canNextPage,
    //pageOptions,
    //gotoPage,
    //nextPage,
    //previousPage,
    //setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    //state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      autoResetHiddenColumns: false,
      //defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0, pageSize: customPageSize, selectedRowIds: 0, sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  return (
    <Fragment>
      {isLoading && <Loader/>}
      {!isLoading && (
        <div className={divClass}>
          <TableStatus
            isSuccess={isSuccess}
            isDataEmpty={!data || isEmpty(data)}
            tableClass={tableClass}
            theadClass={theadClass}
            trClass={trClass}
            thClass={thClass}
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            page={page}
            prepareRow={prepareRow}
            error={error}
            t={t}
          />
        </div>
      )}

      {/*data && !isEmpty(data) && (
        <Pagination
          totalRows={totalDataLength}
          pageChangeHandler={setCurrentPage}
          rowsPerPage={25}
        />
      )*/}
    </Fragment>
  );
};

export default withTranslation()(OperationalBlockTable);