import React, { useState, useEffect, useCallback } from "react";
import { Card, CardBody, CardHeader, Col, Container, Form, Row, Label, Input, FormFeedback } from "reactstrap";
import { withTranslation } from 'react-i18next';
import BreadCrumb from "../../Components/Commons/BreadCrumb";
import GenericModal from "../../Components/Commons/GenericModal";
import * as moment from "moment";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import {
	useCreateCollectTicketMutation, 
	useUpdateCollectTicketMutation,
	useGetCollectTicketByIdQuery,
} from '../../api/api'
import Flatpickr from "react-flatpickr";

const CreateTicketCollect = ({ t, i18n }) => {

	let { id } = useParams();

	const navigate = useNavigate();
	const location = useLocation();

	const [createCollectTicket, 
		{ 
			error: errors, 
			isLoading: isCreationLoading,
			isSuccess: isCreationSuccess,
			data: creationData
		}] = useCreateCollectTicketMutation();

	const [updateCollectTicket, 
		{ 
			error: updateErrors, 
			isLoading: isUpdateLoading,
			isSuccess: isEditionSuccess,
			data: updateData
		}] = useUpdateCollectTicketMutation();

	const { 
		data: order,
		error: orderError,
		isFetching: isTicketCollectLoading,
		isSuccess: isTicketCollectSuccess
	} = useGetCollectTicketByIdQuery(
		id ? id : '',
		{
			refetchOnMountOrArgChange: true,
			skip: location.pathname.includes('/order-sets/') || !id,
		}
	);

	const [successModal, setSuccessModal] = useState(false);
	const [errorModal, setErrorModal] = useState(false);
	const [editedOrderSet, setEditedOrderSet] = useState(null);

	useEffect(() => {
		if (order) {
			let asdasd = moment(order.emission_date).format("YYYY-MM-DD");
			debugger;
			setEditedOrderSet(order);
		}
	}, [isTicketCollectSuccess]);

	useEffect(() => {
		if (isCreationSuccess || isEditionSuccess) {
			setSuccessModal(true);
		}
	}, [isCreationSuccess, isEditionSuccess]);

	useEffect(() => {
		if (errors || updateErrors) {
			setErrorModal(true);
		}
	}, [errors, updateErrors]);


	const successModalButtonPressed = useCallback(() => {
		setSuccessModal(false);
		let data = order ? updateData : creationData;
		navigate(`../../order-sets/${data.order_set.id}`);
	}, [successModal]);
	

	// validation
	const validation = useFormik({
		enableReinitialize: true,
		validateOnChange: false,
		validateOnBlur: false,

		initialValues: {
			reference: (location.state && location.state.reference) || (editedOrderSet && editedOrderSet.reference) || '',
			//campaign_reference: (editedOrderSet && editedOrderSet.campaign_reference) || '',
			emission_date: (editedOrderSet && editedOrderSet.emission_date && editedOrderSet.emission_date) || '',
			//billing_date: (editedOrderSet && editedOrderSet.billing_date && moment(editedOrderSet.billing_date).format("Y-m-d")) || '',
			//zone: (editedOrderSet && editedOrderSet.zone) ||'',
			//territory: (editedOrderSet && editedOrderSet.territory) || '',
			products: (editedOrderSet && editedOrderSet.products) || '',
			quantity: (editedOrderSet && editedOrderSet.quantity.toString()) || '',
			//customer_name: (editedOrderSet && editedOrderSet.customer && editedOrderSet.customer.name) || '',
			//customer_address: (editedOrderSet && editedOrderSet.customer && editedOrderSet.address.address_string) || '',
			//customer_reference: (editedOrderSet && editedOrderSet.customer && editedOrderSet.customer.reference) || '',
		},
		
		validationSchema: Yup.object({
			reference: Yup.string().required(t("Please enter a reference")),
			//campaign_reference: Yup.string().required(t("Please enter a campaign reference")),
			/*emission_date: Yup.string().required(t("Please enter a emition day")),
			billing_date: Yup.string().required(t("Please enter a billing day")),*/
			//quantity: Yup.number().moreThan(-1).required(t("Please enter a quantity")),
			//zone: Yup.number().moreThan(-1).required(t("Please enter a zone")),
			//products: Yup.string().required(t("Please enter the products description")),
			//customer_name: Yup.string().required(t("Please enter a customer name")),
			//customer_address: Yup.string().required(t("Please enter a customer address")),
			//customer_reference: Yup.string().required(t("Please enter a customer reference")),
		}),

		onSubmit: (values) => {
			debugger;
			if(order){
				/*const address = {
					address: values["customer_address"],
					address_string: values["customer_address"],
				};
				const customer = {
					name: values["customer_name"],
					reference: values["customer_reference"]
				};*/
				const collectTicket = {
					reference: values["reference"],
					//campaign_reference: values["campaign_reference"],
					//territory: values["territory"],
					products: values["products"],
					quantity: values["quantity"],
					//zone: values["zone"],
					//customer: customer,
					//address: address,
				};
				if(values["emission_date"] !== ""){
					collectTicket["emission_date"] = values["emission_date"];
				}
				if(location.pathname.includes('/order-sets/')){
					collectTicket["order_set_id"] = id;
				}
				setEditedOrderSet(collectTicket);
				updateCollectTicket({id: id, body: collectTicket});
				validation.resetForm();
				
			} else {
				/*const address = {
					address: values["customer_address"],
					address_string: values["customer_address"],
				};
				const customer = {
					name: values["customer_name"],
					reference: values["customer_reference"]
				};*/
				const collectTicket = {
					reference: values["reference"],
					//emission_date: values["emission_date"],
					//billing_date: values["billing_date"],
					//campaign_reference: values["campaign_reference"],
					//territory: values["territory"],
					//zone: values["zone"],
					products: values["products"],
					quantity: values["quantity"],
					//customer: customer,
					//address: address,
				};
				if(values["emission_date"] !== ""){
					collectTicket["emission_date"] = values["emission_date"];
				}
				if(location.pathname.includes('/order-sets/')){
					collectTicket["order_set_id"] = id;
				}
				setEditedOrderSet(collectTicket);
				createCollectTicket(collectTicket);
				validation.resetForm();
			}
			
		},
	});

	document.title = "Create Collect Ticket | PROGISTICA";

	return (
		<React.Fragment>
			<div className="page-content">
				<GenericModal
					show={successModal}
					title={t("Collect Ticket information saved")}
					message={t('All data was saved successfully')}
					okButtonText={t('OK')}
					onOkClick={() => successModalButtonPressed()}
				/>
				<GenericModal
					show={errorModal}
					title={t("Collect Ticket information not saved")}
					message={t('An error occurred while saving TicketCollect data')}
					okButtonText={t('OK')}
					onOkClick={() => setErrorModal(false)}
				/>
				<Container fluid>
					<BreadCrumb title={order ? t("Edit Collect Ticket") : t("Create Collect Ticket")} pageTitle={t("Collect Tickets")} />
					<Form className="tablelist-form" onSubmit={validation.handleSubmit}>
						<Row>
						
							<Col lg={12}>
								<Card>
									<CardHeader>
										<h5 className="card-title mb-0">{t("Collect Information")}</h5>
									</CardHeader>
									<CardBody>
									
										<Row className="g-3">

											<Col lg={4}>
												<Label htmlFor="reference" className="form-label" >
													{t("Collect Ticket Reference Id")}
												</Label>
												<Input
													name="reference"
													className="form-control"
													id="reference"
													placeholder={t("Collect Ticket Reference Id")}
													onChange={validation.handleChange}
													onBlur={validation.handleBlur}
													value={validation.values.reference || ""}
													invalid={
														(validation.errors.reference) || (errors && errors.reference) || (updateErrors && updateErrors.reference) ? true : false
													}
												></Input>
												{validation.touched.reference && validation.errors.reference ? (
													<FormFeedback type="invalid">{validation.errors.reference}</FormFeedback>
												) : null}
												<FormFeedback type="invalid">{errors && errors.reference ? (<div>{errors.reference}</div>) : null}</FormFeedback>
												<FormFeedback type="invalid">{updateErrors && updateErrors.reference ? (<div>{updateErrors.reference}</div>) : null}</FormFeedback>
											</Col>

											{/*<Col lg={4}>
												<Label htmlFor="campaign_reference" className="form-label" >
													{t("Campaign")}
												</Label>
												<Input
													name="campaign_reference"
													className="form-control"
													id="campaign_reference"
													placeholder="Campaign Id"
													onChange={validation.handleChange}
													onBlur={validation.handleBlur}
													value={validation.values.campaign_reference || ""}
													invalid={
														(validation.errors.campaign_reference) || (errors && errors.campaign_reference) || (updateErrors && updateErrors.campaign_reference) ? true : false
													}
												></Input>
												{validation.touched.campaign_reference && validation.errors.campaign_reference ? (
													<FormFeedback type="invalid">{validation.errors.campaign_reference}</FormFeedback>
												) : null}
												<FormFeedback type="invalid">{errors && errors.campaign_reference ? (<div>{errors.campaign_reference}</div>) : null}</FormFeedback>
												<FormFeedback type="invalid">{updateErrors && updateErrors.campaign_reference ? (<div>{updateErrors.campaign_reference}</div>) : null}</FormFeedback>
											</Col>*/}

											<Col sm={4}>
												<div>
													<Label htmlFor="emission_date" className="form-label" >
														{t("Emition date")}
													</Label>
													<Flatpickr
														className="form-control"
														id="emission_date"
														value={moment(validation.values.emission_date).format("YYYY-MM-DD") || ""}
														placeholder={t("Emition date")}
														options={{
															mode: "single",
															dateFormat: "Y-m-d",
														}}
														rules={{ required: true }}
													onChange={(selectedDates, dateStr, instance) => validation.setFieldValue('emission_date', selectedDates[0])}
													/>
													<FormFeedback type="invalid">{errors && errors.emission_date ? (<div>{errors.emission_date}</div>) : null}</FormFeedback>
													<FormFeedback type="invalid">{updateErrors && updateErrors.emission_date ? (<div>{updateErrors.emission_date}</div>) : null}</FormFeedback>
												</div>
											</Col>

											{/*<Col sm={4}>
												<div>
													<Label htmlFor="billing_date" className="form-label" >
														{t("Billing Day")}
													</Label>
													<Flatpickr
														className="form-control"
														id="billing_date"
														value={validation.values.billing_date || ""}
														placeholder={t("Billing Day")}
														options={{
															mode: "single",
															dateFormat: "Y-m-d",
														}}
														rules={{ required: true }}
													onChange={(selectedDates, dateStr, instance) => validation.setFieldValue('billing_date', selectedDates[0])}
													/>
													<FormFeedback type="invalid">{errors && errors.billing_date ? (<div>{errors.billing_date}</div>) : null}</FormFeedback>
													<FormFeedback type="invalid">{updateErrors && updateErrors.billing_date ? (<div>{updateErrors.billing_date}</div>) : null}</FormFeedback>
												</div>
											</Col>

											<Col lg={4}>
												<Label htmlFor="territory" className="form-label" >
													{t("Territory")}
												</Label>
												<Input
													name="territory"
													className="form-control"
													id="territory"
													placeholder={t("Territory")}
													onChange={validation.handleChange}
													onBlur={validation.handleBlur}
													value={validation.values.territory || ""}
													invalid={
														(validation.errors.territory) || (errors && errors.territory) || (updateErrors && updateErrors.territory) ? true : false
													}
												></Input>
												{validation.touched.territory && validation.errors.territory ? (
													<FormFeedback type="invalid">{validation.errors.territory}</FormFeedback>
												) : null}
												<FormFeedback type="invalid">{errors && errors.territory ? (<div>{errors.territory}</div>) : null}</FormFeedback>
												<FormFeedback type="invalid">{updateErrors && updateErrors.territory ? (<div>{updateErrors.territory}</div>) : null}</FormFeedback>
											</Col>

											<Col lg={4}>
												<Label htmlFor="zone" className="form-label" >
													{t("Zone Id")}
												</Label>
												<Input
													name="zone"
													className="form-control"
													id="zone"
													placeholder={t("Zone Id")}
													onChange={validation.handleChange}
													onBlur={validation.handleBlur}
													value={validation.values.zone || ""}
													invalid={
														(validation.errors.zone) || (errors && errors.zone) || (updateErrors && updateErrors.zone) ? true : false
													}
												></Input>
												{validation.touched.zone && validation.errors.zone ? (
													<FormFeedback type="invalid">{validation.errors.zone}</FormFeedback>
												) : null}
												<FormFeedback type="invalid">{errors && errors.zone ? (<div>{errors.zone}</div>) : null}</FormFeedback>
												<FormFeedback type="invalid">{updateErrors && updateErrors.zone ? (<div>{updateErrors.zone}</div>) : null}</FormFeedback>
											</Col>*/}

											<Col lg={9}>
												<Label htmlFor="name" className="form-label" >
													{t("Products")}
												</Label>
												<textarea
													name="products"
													className="form-control"
													id="products"
													placeholder={t("Products")}
													rows={2}
													onChange={validation.handleChange}
													onBlur={validation.handleBlur}
													value={validation.values.products || ""}
													invalid={
														(validation.errors.products) || (errors && errors.products) || (updateErrors && updateErrors.products) ? true : false
													}
												></textarea>
												{validation.errors.products ? (
													<FormFeedback type="invalid">{validation.errors.products}</FormFeedback>
												) : null}
												<FormFeedback type="invalid">{errors && errors.products ? (<div>{errors.products}</div>) : null}</FormFeedback>
												<FormFeedback type="invalid">{updateErrors && updateErrors.products ? (<div>{updateErrors.products}</div>) : null}</FormFeedback>
											</Col>

											<Col lg={3}>
												<Label htmlFor="quantity" className="form-label" >
													{t("Product Quantity")}
												</Label>
												<Input
													name="quantity"
													className="form-control"
													id="quantity"
													placeholder={t("Product Quantity")}
													onChange={validation.handleChange}
													onBlur={validation.handleBlur}
													value={validation.values.quantity || ""}
													invalid={
														(validation.errors.quantity) || (errors && errors.quantity) || (updateErrors && updateErrors.quantity) ? true : false
													}
												></Input>
												{validation.touched.quantity && validation.errors.quantity ? (
													<FormFeedback type="invalid">{validation.errors.quantity}</FormFeedback>
												) : null}
												<FormFeedback type="invalid">{errors && errors.quantity ? (<div>{errors.quantity}</div>) : null}</FormFeedback>
												<FormFeedback type="invalid">{updateErrors && updateErrors.quantity ? (<div>{updateErrors.quantity}</div>) : null}</FormFeedback>
											</Col>

										</Row>
									
									</CardBody>
								</Card>
							</Col>
							{/*<Col lg={4}>
								<Card>
									<CardHeader>
										<h5 className="card-title mb-0">{t("Customer Information")}</h5>
									</CardHeader>
									<CardBody>
										<div className="mb-3">
											<Label htmlFor="name" className="form-label" >
												{t("Name")}
											</Label>
											<textarea
												name="customer_name"
												className="form-control"
												id="customer_name"
												placeholder={t("Name")}
												rows={2}
												onChange={validation.handleChange}
												onBlur={validation.handleBlur}
												value={validation.values.customer_name || ""}
												invalid={
													(validation.errors.customer_name) || (errors && errors.customer && errors.customer.name) || (updateErrors && updateErrors.customer && updateErrors.customer.name) ? true : false
												}
											></textarea>
											{validation.touched.customer_name && validation.errors.customer_name ? (
												<FormFeedback type="invalid">{validation.errors.customer_name}</FormFeedback>
											) : null}
											<FormFeedback type="invalid">{errors && errors.customer && errors.customer.name ? (<div>{errors.customer.name}</div>) : null}</FormFeedback>
											<FormFeedback type="invalid">{updateErrors && updateErrors.customer && updateErrors.customer.name ? (<div>{updateErrors.customer.name}</div>) : null}</FormFeedback>
										</div>
										<div className="mb-3">
											<Label htmlFor="customer_reference" className="form-label" >
												{t("Customer Reference")}
											</Label>
											<Input
												name="customer_reference"
												className="form-control"
												id="customer_reference"
												placeholder={t("Customer Reference")}
												onChange={validation.handleChange}
												onBlur={validation.handleBlur}
												value={validation.values.customer_reference || ""}
												invalid={
													(validation.errors.customer_reference) || (errors && errors.customer && errors.customer.reference) || (updateErrors && updateErrors.customer && updateErrors.customer.reference) ? true : false
												}
											></Input>
											{validation.touched.customer_reference && validation.errors.customer_reference ? (
												<FormFeedback type="invalid">{validation.errors.customer_reference}</FormFeedback>
											) : null}
											<FormFeedback type="invalid">{errors && errors.customer && errors.customer.reference ? (<div>{errors.customer.reference}</div>) : null}</FormFeedback>
											<FormFeedback type="invalid">{updateErrors && updateErrors.customer && updateErrors.customer.reference ? (<div>{updateErrors.customer.reference}</div>) : null}</FormFeedback>
										</div>
										<div>
											<Label htmlFor="address" className="form-label" >
												{t("Address")}
											</Label>
											<textarea
												name="customer_address"
												className="form-control"
												id="customer_address"
												placeholder={t("Address")}
												onChange={validation.handleChange}
												onBlur={validation.handleBlur}
												value={validation.values.customer_address || ""}
												invalid={
													(validation.errors.customer_address) || (errors && errors.address) || (updateErrors && updateErrors.address) ? true : false
												}
											></textarea>
											{validation.touched.customer_address && validation.errors.customer_address ? (
												<FormFeedback type="invalid">{validation.errors.address}</FormFeedback>
											) : null}
											<FormFeedback type="invalid">{errors && errors.address ? (<div>{errors.address}</div>) : null}</FormFeedback>
											<FormFeedback type="invalid">{updateErrors && updateErrors.address ? (<div>{updateErrors.address}</div>) : null}</FormFeedback>
										</div>
									</CardBody>
								</Card>
							</Col>*/}
							<Col lg={12}>
								<div className="text-end">
									<button type="submit" className="btn btn-success">{order ? t('Update') : t('Create')}</button>
								</div>
							</Col>
						</Row>
					</Form>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default withTranslation()(CreateTicketCollect);