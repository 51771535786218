import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
	Card,
	CardBody,
	CardHeader,
	Row,
	Nav,
	NavItem,
	NavLink,
	Badge
} from "reactstrap";
import * as moment from "moment";
import { Link, useParams } from "react-router-dom";
import OrdersTableBase from "../../Components/Commons/OrdersTableBase";
import DeleteModal from "../../Components/Commons/DeleteModal";
import classnames from "classnames";
import OrderStatus from "../OrderSets/OrderStatus";
import { isEmpty } from "lodash";
import Pagination from "../../Components/Commons/Pagination";
import { useTranslation } from "react-i18next";

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ExportCSVModal from "../../Components/Commons/ExportCSVModal";

import { useGetOrderImportOrderSetsQuery, useGetOrderImportByIdQuery } from '../../api/api'

const OrdersImportOrders = () => {

	let { reference } = useParams();
	const { t } = useTranslation();

	const defaultdate = () => {
		let d = new Date(),
			months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
		let h = (d.getHours() % 12) || 12;
		let ampm = d.getHours() < 12 ? "AM" : "PM";
		return ((d.getDate() + ' ' + months[d.getMonth()] + ', ' + d.getFullYear() + ", " + h + ":" + d.getMinutes() + " " + ampm).toString());
	};

	const [currentPage, setCurrentPage] = useState(1);

	const [modal, setModal] = useState(false);
	const [activeTab, setActiveTab] = useState("1");

	const [orderList, setOrderList] = useState(null);
	const [orderImportErrors, setOrderImportErrors] = useState([]);
	const [isExportCSV, setIsExportCSV] = useState(false);

	const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
	const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

	const [date, setDate] = useState(defaultdate());

	const [isEdit, setIsEdit] = useState(false);

	const [deleteModal, setDeleteModal] = useState(false);
	const [deleteModalMulti, setDeleteModalMulti] = useState(false);

	const { 
		data: ordersImport,
		error: ordersImportErrors,
		isFetching: isOrdersImportLoading,
		isSuccess: isOrdersImportSuccess
	} = useGetOrderImportByIdQuery(
		reference,
		{
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

	const { 
		data: orderSetsPages,
		error,
		isFetching: isOrderSetsLoading,
		isSuccess: isOrderSetsSuccess
	} = useGetOrderImportOrderSetsQuery(
		{
			id: reference,
			page: currentPage
		},
		{
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

	useEffect(() => {
		if (orderSetsPages && orderSetsPages.count > 0) {
			setOrderList(orderSetsPages.results);
		}
	}, [orderSetsPages]);

	useEffect(() => {
		if (ordersImport && ordersImport.validation_errors) {
			setOrderImportErrors(ordersImport.validation_errors);
		}
		if (ordersImport && ordersImport.errors) {
			setOrderImportErrors(ordersImport.errors);
		}
	}, [ordersImport]);

	const toggle = useCallback(() => {
		if (modal) {
			setModal(false);
			setOrder(null);
		} else {
			setModal(true);
			setDate(defaultdate());
		}
	}, [modal]);

	const handleOrderClick = useCallback((arg) => {
		const order = arg;
		setOrder({
			_id: order._id,
			orderId: order.orderId,
			customer: order.customer,
			product: order.product,
			orderDate: order.orderDate,
			ordertime: order.ordertime,
			amount: order.amount,
			payment: order.payment,
			status: order.status
		});

		setIsEdit(true);
		toggle();
	}, [toggle]);

	const onClickDelete = (order) => {
		setOrder(order);
		setDeleteModal(true);
	};

	const handleDeleteOrder = () => {
		if (order) {
			//dispatch(onDeleteOrder(order._id));
			setDeleteModal(false);
		}
	};

	const toggleTab = (tab, type) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
			let filteredOrders = orderSetsPages.results;
			if (type !== "all") {
				filteredOrders = orderSetsPages.results.filter((order) => order.status === type);
			}
			setOrderList(filteredOrders);
		}
	};

	const deleteMultiple = () => {
		const checkall = document.getElementById("checkBoxAll");
		selectedCheckBoxDelete.forEach((element) => {
			//dispatch(onDeleteOrder(element.value));
			setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
		});
		checkall.checked = false;
		setIsMultiDeleteButton(false);
	};

	// Column
	const columns = useMemo(
		() => [
			{
				Header: t("Order Id"),
				accessor: "id",
				filterable: false,
				Cell: (cell) => {
					return <Link to={`../order-sets/${cell.value}`} className="fw-medium link-primary">{parseInt(cell.row.original.reference)}</Link>;
				},
			},
			{
				Header: t("Consultant"),
				accessor: "customer",
				filterable: false,
				Cell: (cell) => (
					<>
						<div className="d-flex align-items-center">
							<div className="flex-grow-1">
								<h5 className="fs-14 mb-1">
									{" "}
									{cell.row.original.customer.name}
								</h5>
								<p className="text-muted mb-0">
									<small className="text-muted"> {cell.row.original.address != null ? cell.row.original.address.address_string.substring(0, 50) : ''}</small>
								</p>
							</div>
						</div>
					</>
				),
			},
			{
				Header: t("Boxes"),
				accessor: "box_quantity",
				filterable: false,
			},
			{
				Header: t("Outs of Box"),
				accessor: "quantity",
				filterable: false,
			},
			{
				Header: t('Status'),
				accessor: 'status',
				Cell: (order) => {
					return <OrderStatus status={order.row.original.status} status_text={order.row.original.status_text} />;
				}
			},
			{
				Header: t("Action"),
				Cell: (cellProps) => {
					return (
						<ul className="list-inline hstack gap-2 mb-0">
							<li className="list-inline-item">
								<Link
									to="/order-detail"
									className="text-primary d-inline-block"
								>
									<i className="ri-eye-fill fs-16"></i>
								</Link>
							</li>
							<li className="list-inline-item edit">
								<Link
									to="#"
									className="text-primary d-inline-block edit-item-btn"
									onClick={() => {
										const orderData = cellProps.row.original;
										handleOrderClick(orderData);
									}}
								>
									<i className="ri-pencil-fill fs-16"></i>
								</Link>
							</li>
							<li className="list-inline-item">
								<Link
									to="#"
									className="text-danger d-inline-block remove-item-btn"
									onClick={() => {
										const orderData = cellProps.row.original;
										onClickDelete(orderData);
									}}
								>
									<i className="ri-delete-bin-5-fill fs-16"></i>
								</Link>
							</li>
						</ul>
					);
				},
			},
		],
		[]
	);

	// Errors column
	const errorsColumns = useMemo(
		() => [
			{
				Header: t("Column"),
				accessor: "index",
			},
			{
				Header: t("Message"),
				accessor: "message",
			},
		]
	);

	document.title = "Sistema de pedidos PROGISTICA";
	return (
		<React.Fragment>
			<DeleteModal
				show={deleteModal}
				onDeleteClick={handleDeleteOrder}
				onCloseClick={() => setDeleteModal(false)}
			/>
			<DeleteModal
				show={deleteModalMulti}
				onDeleteClick={() => {
					deleteMultiple();
					setDeleteModalMulti(false);
				}}
				onCloseClick={() => setDeleteModalMulti(false)}
			/>
			<Card id="orderList">
				<CardHeader className="border-0">
					<Row className="align-items-center">
						<div className="col-sm">
							<h5 className="card-title mb-0">{t("Imported Orders")} <Badge color="primary" className="ms-1">{orderSetsPages ? orderSetsPages.count : '' }</Badge></h5>
						</div>
						{/*<div className="col-sm-auto">
							<div className="d-flex gap-1 flex-wrap">
								<button type="button" className="btn btn-info" onClick={() => setIsExportCSV(true)}>
									<i className="ri-file-download-line align-bottom me-1"></i>{" "}
									{t("Export")}
								</button>
								{" "}
								{isMultiDeleteButton && <button className="btn btn-soft-danger"
									onClick={() => setDeleteModalMulti(true)}
								><i
									className="ri-delete-bin-2-line"></i></button>}
							</div>
						</div>*/}
					</Row>
				</CardHeader>
				<CardBody className={orderImportErrors !== undefined && orderImportErrors.length > 0 ? "pt-0" : ""}>
					<div>
						{orderImportErrors !== undefined && orderImportErrors.length > 0 && (
							<Nav
								className="nav-tabs nav-tabs-custom nav-success mb-3"
								role="tablist"
							>
								<NavItem>
									<NavLink
										className={classnames(
											{ active: activeTab === "1" },
											"fw-semibold"
										)}
										onClick={() => {
											toggleTab("1", "all");
										}}
										href="#"
									>
										<i className="ri-inbox-archive-line me-1 align-bottom"></i>{" "}
										{t("Imported Orders")}
									</NavLink>
								</NavItem>
								
								<NavItem>
									<NavLink
										className={classnames(
											{ active: activeTab === "2" },
											"fw-semibold"
										)}
										onClick={() => {
											toggleTab("2", "Errors");
										}}
										href="#"
									>
										<i className="ri-error-warning-line me-1 align-bottom"></i>{" "}
										{t("Errors")} <Badge color="danger" className="ms-1">{orderImportErrors.length}</Badge>
									</NavLink>
								</NavItem>
								
							</Nav>
						)}
						{activeTab  === "1" && (
							<>
								<OrdersTableBase
									columns={columns}
									data={(orderList || [])}
									isLoading={isOrderSetsLoading}
									isSuccess={isOrderSetsSuccess}
									error={error}
									manualPagination={true}
									totalDataLength={( orderSetsPages !== undefined ? orderSetsPages.count : 0)}
									customPageSize={25}
									divClass="table-responsive table-card mb-1"
									tableClass="align-middle table-nowrap"
									theadClass="table-light text-muted"
								/>
								{orderSetsPages && !isEmpty(orderSetsPages.results) && (
									<Pagination
										totalRows={orderSetsPages.count}
										pageChangeHandler={setCurrentPage}
										rowsPerPage={25}
									/>
								)}
							</>
						)}
						
						{activeTab  === "2" && <OrdersTableBase
								columns={errorsColumns}
								data={(orderImportErrors || [])}
								setCurrentPage={setCurrentPage}
								isLoading={isOrderSetsLoading}
								isSuccess={isOrderSetsSuccess}
								error={error}
								manualPagination={false}
								totalDataLength={( orderImportErrors !== undefined ? orderImportErrors.length : 0)}
								customPageSize={25}
								divClass="table-responsive table-card mb-1"
								tableClass="align-middle table-nowrap"
								theadClass="table-light text-muted"
								isOrderFilter={true}
							/>
						}
					</div>
				</CardBody>
			</Card>
		</React.Fragment>
	);
};

export default OrdersImportOrders;