import React from 'react';
import ReactApexChart from "react-apexcharts";


const PickingOrderStatusPlot = ({ seriesData, chartsColor, height="36", width="36"}) => {
	// const series=  isApexSeriesData.series,
	const series = [seriesData];

	const options = {
		chart: {
			type: 'radialBar',
			width: 36,
			height: 36,
			sparkline: {
				enabled: !0
			}
		},
		dataLabels: {
			enabled: !1
		},
		plotOptions: {
			radialBar: {
				hollow: {
					margin: 0,
					size: '50%'
				},
				track: {
					margin: 1
				},
				dataLabels: {
					show: !1
				}
			}
		},
		colors: [chartsColor]
	};
	return (
		<React.Fragment>
			<ReactApexChart dir="ltr"
				options={options}
				series={[...series]}
				type="radialBar"
				height={height}
				width={width}
				className="apex-charts"
			/>
		</React.Fragment>
	);
};

export default PickingOrderStatusPlot;