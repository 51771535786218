import React, { useState, useEffect, useCallback } from 'react';
import { Container, Col, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Commons/BreadCrumb';
import { withTranslation } from 'react-i18next';
import { Card, CardHeader, CardBody, Table } from "reactstrap";
import { Link, useParams, useNavigate } from 'react-router-dom';
import Loader from "../../Components/Commons/Loader";
import OrderExportStatus from "./OrderExportStatus";

import complete_icon from "../../images/complete_icon.png";
import error_icon from "../../images/error_icon.png";
import wait_icon from "../../images/wait_icon.png";

import { useGetOrderExportByIdQuery } from '../../api/api'

import * as moment from "moment";

const OrdersExportDetails = ({t, i18n}) => {
    document.title="Tasks Details | Sistema de pedidos PROGISTICA";

    let { reference } = useParams();

	const [skipPolling, setSkipPolling] = useState(false);

	const { 
		data: ordersExport,
		error,
		isFetching: isOrdersExportLoading,
		isSuccess: isOrdersExportSuccess
	} = useGetOrderExportByIdQuery(
		reference,
		{
			pollingInterval: skipPolling ? 0 : 5000,
			refetchOnMountOrArgChange: true,
		}
	);

	useEffect(() => {
		if (ordersExport) {
			setSkipPolling(ordersExport.status !== 3);
            console.log(ordersExport.status !== 3);
		}
	}, [ordersExport]);

	const handleValidDate = date => {
		const date1 = moment(date).format("DD/MM");
		return date1;
	};

	const handleValidTime = (time) => {
		const updateTime = moment(time).format('HH:mm');
		return updateTime;
	};

    const handleDownload = useCallback((value) => {
        window.open(ordersExport.file_url, "_blank");
	});

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t("Orders Export Details")} pageTitle={t("Orders Exports")} />
                    <Row>
                        <Col xl={3}>
                            <Card className="mb-3">
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">{t("Orders Export Details")}</h4>
                                </CardHeader>
                                <CardBody>
                                <div className="text-center mb-4">
                                    {isOrdersExportLoading && <Loader/>}
                                    {isOrdersExportSuccess && (
                                        <div className="text-center">
                                            <h2 className="overview-companyname">
                                                {ordersExport.reference}
                                            </h2>
                                            <p className="text-muted m-0">{t("Orders Import Reference")}</p>
                                        </div>
                                    )}
                                </div>
                                    {isOrdersExportLoading && <Loader/>}
                                    {isOrdersExportSuccess && (
                                        <div className="table-responsive table-card">
                                            <Table className="table mb-0">
                                                <tbody>
                                                    <tr>
                                                        <td className="fw-medium">{t("Created at")}</td>
                                                        <td>{handleValidDate(ordersExport.created_at)} {handleValidTime(ordersExport.created_at)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-medium">{t("Status")}</td>
                                                        <td><OrderExportStatus status={ordersExport.status} status_text={ordersExport.status_text} /></td>
                                                    </tr>
                                                </tbody>
                                            
                                            </Table>
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="d-flex mb-3">
                                        <h6 className="card-title mb-0 flex-grow-1">{t("Created By")}</h6>
                                    </div>
                                    {isOrdersExportLoading && <Loader/>}
                                    {isOrdersExportSuccess && (
                                        <ul className="list-unstyled vstack gap-3 mb-0">
                                            <li>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                    </div>
                                                    <div className="flex-grow-1 ms-2">
                                                        <h6 className="mb-1"><Link to={`../users/${ordersExport.creator.id}`} >{ordersExport.creator.first_name} {ordersExport.creator.last_name}</Link></h6>
                                                        <p className="text-muted mb-0">{ordersExport.creator.email}</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </Col>
                        <Col xl={9}>
                            <Card>
                                <CardBody>

                                    {isOrdersExportLoading && <Loader/>}
                                    {isOrdersExportSuccess && (

                                        
                                        <div className="text-center">
                                            
                                            {/*
                                            ERROR = 1
                                            ENTERED = 2
                                            IN_PROGRESS = 3
                                            SUCCESS = 4
                                            VALIDATION_ERROR = 5
                                            */}

                                            {(ordersExport.status == 1 || ordersExport.status == 5)&& (
                                                <>
                                                    <Row className="justify-content-center">
                                                        <Col lg={9}>
                                                            <h4 className="mt-4 fw-semibold">{t("Error")}</h4>
                                                            <p className="text-muted mt-3">
                                                                {t("An error occurred while downloading data")}
                                                            </p>
                                                            <img src={error_icon}  width="100px" />
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}

                                            {ordersExport.status == 2 && (
                                                <>
                                                    <Row className="justify-content-center">
                                                        <Col lg={9}>
                                                            <h4 className="mt-4 fw-semibold">{t("Entered")}</h4>
                                                            <p className="text-muted mt-3">
                                                                {t("Data download has been entered")}
                                                            </p>
                                                            <div className="mt-4">
                                                                <Loader/>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}

                                            {ordersExport.status == 3 && (
                                                <>
                                                    <Row className="justify-content-center">
                                                        <Col lg={9}>
                                                            <h4 className="mt-4 fw-semibold">{t("In Progress")}</h4>
                                                            <p className="text-muted mt-3">
                                                                {t("Generating the download file, please wait")}
                                                            </p>
                                                            <div className="mt-4">
                                                                <Loader/>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}

                                            {ordersExport.status == 4 && (
                                                <>
                                                    <Row className="justify-content-center">
                                                        <Col lg={9}>
                                                            <h4 className="mt-4 fw-semibold">{t("Success")}</h4>
                                                            <p className="text-muted mt-3">
                                                                {t("The file has been generated. Download it by pressing the following button")}
                                                            </p>
                                                            <img src={complete_icon} width="100px" />
                                                            <div className="mt-4">
                                                                {<button
                                                                    type="button"
                                                                    onClick={() => handleDownload()}
                                                                    className="btn btn-primary"
                                                                    data-bs-toggle="modal"
                                                                    data-bs-target="#exampleModal"
                                                                >
                                                                    {t("Download")}
                                                                </button>}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(OrdersExportDetails);