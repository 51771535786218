import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import * as moment from "moment";
import Flatpickr from "react-flatpickr";
import ErrorModal from "../../Components/Commons/ErrorModal";
import ConfirmModal from "../../Components/Commons/ConfirmModal";
import { withTranslation } from 'react-i18next';

import {
	Card,
	CardBody,
	Col,
	Row,
	Input,
} from "reactstrap";

import Loader from "../../Components/Commons/Loader";
import Pagination from "../../Components/Commons/Pagination";
import EmptyState from "../../Components/Commons/EmptyState";
import OrderExportStatus from "./OrderExportStatus";

import { 
	useGetOrderExportsQuery,
	useExportOrdersMutation,
	useGetAllCompaniesQuery
} from '../../api/api'

const List = ({t, i18n}) => {

	let navigate = useNavigate();

	const [currentPage, setCurrentPage] = useState(1);

	const [modal, setModal] = useState(false);
	
	const [importedInput, setImportedInput] = useState(null);
	const [ordersImportedModal, setOrdersImportedModal] = useState(false);

	const [errorModal, setErrorModal] = useState(false);
	const [errorTitle, setErrorTitle] = useState(false);
	const [errorDescription, setErrorDescription] = useState(false);

	function getHeaderClass(status) {
		if(status === 1){
			return "bg-soft-danger";
		} else if (status === 2) {
			return "bg-soft-primary";
		} else if (status === 3) {
			return "bg-soft-secondary";
		} else if (status === 4) {
			return "bg-soft-success";
		} else if (status === 5) {
			return "bg-soft-warning";
		}
	}

	const { 
		data: orderExportsPages,
		error,
		isFetching: isOrderExportsLoading,
		isSuccess: isOrderExportsSuccess
	} = useGetOrderExportsQuery(
		{
			page: currentPage,
		}, {
			refetchOnMountOrArgChange: true,
			skip: false,
		}
	);

	const handleValidBillingDate = date => {
		const date1 = moment(date).format("DD/MM");
		return date1;
	};
	
	const handleValidTime = (time) => {
		const updateTime = moment(time).format('HH:mm');
		return updateTime;
	};

	const handleNavigateToInput = () => {
		let data = importedInput;
		navigate(`../orders-import/${data.input_reference}`);
	};

	const handleImportedError = (error) => {
	let errorString;
				
		if(error.data instanceof Array){
			errorString = error.data.join(', ');
		} else if(error.error){
			errorString = error.error;
		} else {
			errorString = t("Unknown error")
		}
		setModal(false);
		setErrorTitle(t("Error when trying to import orders"));
		setErrorDescription(errorString);
		setErrorModal(true);
	};
	
	return (
		<React.Fragment>
			<ToastContainer closeButton={false} />
			<ErrorModal
				show={errorModal}
				onCloseClick={() => setErrorModal(false)}
				title={errorTitle}
				description={errorDescription}
			/>
			<ConfirmModal
				show={ordersImportedModal}
				onConfirmClick={handleNavigateToInput}
				onCloseClick={() => setOrdersImportedModal(false)}
				title={t("Order import created")}
				question={t("You will be redirected to the import detail so you can see the progress.")}
				yesQuestion="Ok"
			/>
			<Row className="g-4 mb-3">
				{/*<div className="col-sm-auto">
					<div>
						<button
							type="button"
							className="btn btn-success add-btn"
							id="create-btn"
							onClick={() => {
								setModal(true);
							}}
						>
							<i className="ri-download-line align-bottom me-1"></i> {t("Import Orders")}
						</button>
					</div>
				</div>*/}
				<div className="col-xxl-10 d-flex justify-content-xxl-end gap-2">
					<Row>

						{/*<Col sm={4}>
							<Flatpickr
								className="form-control"
								id="datepicker-billing-date"
								placeholder={t("Billing Day")}
								options={{
									altInput: true,
									altFormat: "F j, Y",
									mode: "range",
									dateFormat: "DD-MM-YYYY",
								}}
								onChange={(date) => {
									setCurrentPage(1);
									if(date[0]!== undefined){
										const date1 = moment(date[0]).format("DD-MM-YYYY");
										setBillingStartDate(date1);
									} else {
										setBillingStartDate(null);
									}
									if(date[1]!== undefined){
										const date1 = moment(date[1]).format("DD-MM-YYYY");
										setBillingEndDate(date1);
									} else {
										setBillingEndDate(null);
									}
								}}
							/>
						</Col>*/}

						{/*<Col sm={4}>
							<Flatpickr
								className="form-control"
								id="datepicker-created-at"
								placeholder={t("Created at")}
								options={{
									altInput: true,
									altFormat: "F j, Y",
									mode: "range",
									dateFormat: "DD-MM-YYYY",
								}}
								onChange={(date) => {
									setCurrentPage(1);
									if(date[0]!== undefined){
										const date1 = moment(date[0]).format("DD-MM-YYYY");
										setCreationStartDate(date1);
									} else {
										setCreationStartDate(null);
									}
									if(date[1]!== undefined){
										const date1 = moment(date[1]).format("DD-MM-YYYY");
										setCreationEndDate(date1);
									} else {
										setCreationEndDate(null);
									}
								}}
							/>
						</Col>*/}
			
						{/*<Col sm={4}>
							<Input
								name="campaignId"
								id="id-field"
								className="form-control"
								placeholder={t("Campaign Id")}
								type="text"
								validate={{
									required: { value: true },
								}}
								onChange={(e) => {
									setCurrentPage(1);
									setCampaign(e.target.value && e.target.value.length > 0 ? e.target.value : null);
								}}
							/>
						</Col>*/}
					</Row>
					
				</div>
			</Row>

			<div className="row mt-4 pt-4">
				{isOrderExportsLoading && <Loader/>}
				{!isOrderExportsLoading &&
					(orderExportsPages != null ? orderExportsPages.results : []).map((item, index) => (
						<React.Fragment key={index}>
							<Col xxl={3} sm={6} className="project-card">
								<Card className="card-height-100">
									<CardBody>
										<div className={`p-3 mt-n3 mx-n3 ${getHeaderClass(item.status)} rounded-top`}>
											<div className="d-flex align-items-center">
												<div className="flex-grow-1">
													<Link to={`${item.reference}`} className="text-dark h5 mb-0 fs-14">{item.reference}</Link>
												</div>
											</div>
										</div>

										{/*<h5 className="mt-3 fs-14">{item.company.name}</h5>*/}

										<div className="py-2">
											<Row className="gy-3">
												<Col xs={6}>
													<div>
														<p className="text-muted mb-1">{t("Status")}</p>
														<OrderExportStatus status={item.status} status_text={item.status_text} />
													</div>
												</Col>
												<Col xs={6}>
													<div>
														<p className="text-muted mb-1">{t("Created at")}</p>
														<h5 className="fs-14">{handleValidBillingDate(item.created_at)} <small className="text-muted"> {handleValidTime(item.created_at)} </small></h5>
													</div>
												</Col>
											</Row>
										</div>
										
										{/*<div className="mt-auto mt-1">
											<div className="d-flex mb-2">
												<div className="flex-grow-1">
													<div>{t("Orders")}</div>
												</div>
												<div className="flex-shrink-0">
													<div><i className="ri-list-check align-bottom me-1 text-muted"></i> {item.ready_count}/{item.row_count}</div>
												</div>
											</div>
											<div className="progress progress-sm animated-progess">
												<div className="progress-bar bg-success"
													role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
													style={{ width: handlePercentage(item.ready_count, item.row_count) }}>
												</div>
											</div>
										</div>*/}
									</CardBody>
									<div className="card-footer bg-transparent border-top-dashed py-2">
										<div className="d-flex align-items-center">
											<div className="flex-shrink-0">
												<div className="d-flex align-items-center">
													<p className="text-muted mb-0 me-2">{t("Created by:")}</p>
													<p className="text-muted mb-0 me-2">{item.creator.first_name} {item.creator.last_name}</p>
												</div>
											</div>
										</div>
									</div>
								</Card>
							</Col>
						</React.Fragment>
					)
				)}
				{!isOrderExportsLoading && orderExportsPages && orderExportsPages.count == 0 && (
					<EmptyState
						title={t("No records found")}
						description={t("Change the filters for better results")}
					/>
				)}
			</div>
			{orderExportsPages && orderExportsPages.count > 0 && (
				<Pagination
					totalRows={orderExportsPages.count}
					pageChangeHandler={setCurrentPage}
					rowsPerPage={25}
				/>
			)}
		</React.Fragment>
	);
};

export default withTranslation()(List);