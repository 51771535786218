module.exports = {
  google: {
    API_KEY: "AIzaSyBpNNDkG-LFxaaW_b3OHEVMd74n92Lz49Q",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    API_URL: "https://transchile-backend.optiroute.cl/api/v1/",
  }
};
