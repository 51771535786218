import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle, Form } from 'reactstrap';
import { ToastContainer } from 'react-toastify';

//import images
import logoSm from "../../images/logo-sm.png";
import logoDark from "../../images/logo-dark.png";
import logoLight from "../../images/logo-light.png";
import logoLongDark from "../../images/logo-long-dark.png";

//import Components
import SearchOption from '../Commons/SearchOption';
import LanguageDropdown from '../Commons/LanguageDropdown';
import WebAppsDropdown from '../Commons/WebAppsDropdown';
import MyCartDropdown from '../Commons/MyCartDropdown';
import FullScreenDropdown from '../Commons/FullScreenDropdown';
import NotificationDropdown from '../Commons/NotificationDropdown';
import ProfileDropdown from '../Commons/ProfileDropdown';
import LightDark from '../Commons/LightDark';

import { changeSidebarVisibility } from '../../slices/thunks';
import { useSelector, useDispatch } from "react-redux";

const HeaderNoSideBar = ({ onChangeLayoutMode, layoutModeType, headerClass }) => {
	const dispatch = useDispatch();

	const { sidebarVisibilitytype } = useSelector(state => ({
		sidebarVisibilitytype: state.Layout.sidebarVisibilitytype
	}));

	const [search, setSearch] = useState(false);
	const toogleSearch = () => {
		setSearch(!search);
	};

	return (
		<React.Fragment>
			<header id="page-topbar-no-sidebar" className={headerClass}>
				<div className="layout-width">
					<div className="navbar-header">
						<img src={logoLongDark} alt="" height="27" />
						<div className="d-flex">

							<div className="navbar-brand-box horizontal-logo">
								<Link to="/" className="logo logo-dark">
									<span className="logo-sm">
										<img src={logoSm} alt="" height="22" />
									</span>
									<span className="logo-lg">
										<img src={logoDark} alt="" height="27" />
									</span>
								</Link>

								<Link to="/" className="logo logo-light">
									<span className="logo-sm">
										<img src={logoSm} alt="" height="22" />
									</span>
									<span className="logo-lg">
										<img src={logoLight} alt="" height="27" />
									</span>
									
								</Link>
							</div>

							<ToastContainer style={{ width: "500px" }}/>

							<SearchOption />

						</div>

						<div className="d-flex align-items-center">

							<LanguageDropdown />

							{/* FullScreenDropdown */}
							<FullScreenDropdown />

							{/* Dark/Light Mode set */}
							<LightDark
								layoutMode={layoutModeType}
								onChangeLayoutMode={onChangeLayoutMode}
							/>

							{/* ProfileDropdown */}
							<ProfileDropdown />
						</div>
					</div>
				</div>
			</header>
		</React.Fragment>
	);
};

export default HeaderNoSideBar;