import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import withRouter from '../Commons/withRouter';

//import Components
import Header from './Header';
import HeaderNoSideBar from './HeaderNoSideBar';
import Footer from './Footer';
import RightSidebar from '../Commons/RightSidebar';

//import actions
import {
	changeLayout,
	changeSidebarTheme,
	changeLayoutMode,
	changeLayoutWidth,
	changeLayoutPosition,
	changeTopbarTheme,
	changeLeftsidebarSizeType,
	changeLeftsidebarViewType,
	changeSidebarImageType,
	changeSidebarVisibility
} from "../../slices/thunks";

//redux
import { useSelector, useDispatch } from "react-redux";

const ClientsLayout = (props) => {
	const [headerClass, setHeaderClass] = useState("");
	const dispatch = useDispatch();
	const {
		layoutType,
		leftSidebarType,
		layoutModeType,
		layoutWidthType,
		layoutPositionType,
		topbarThemeType,
		leftsidbarSizeType,
		leftSidebarViewType,
		leftSidebarImageType,
		sidebarVisibilitytype
	} = useSelector(state => ({
		layoutType: state.Layout.layoutType,
		leftSidebarType: state.Layout.leftSidebarType,
		layoutModeType: state.Layout.layoutModeType,
		layoutWidthType: state.Layout.layoutWidthType,
		layoutPositionType: state.Layout.layoutPositionType,
		topbarThemeType: state.Layout.topbarThemeType,
		leftsidbarSizeType: state.Layout.leftsidbarSizeType,
		leftSidebarViewType: state.Layout.leftSidebarViewType,
		leftSidebarImageType: state.Layout.leftSidebarImageType,
		sidebarVisibilitytype: state.Layout.sidebarVisibilitytype
	}));

	/*
	layout settings
	*/
	useEffect(() => {
		if (
			layoutType ||
			leftSidebarType ||
			layoutModeType ||
			layoutWidthType ||
			layoutPositionType ||
			topbarThemeType ||
			leftsidbarSizeType ||
			leftSidebarViewType ||
			leftSidebarImageType ||
			sidebarVisibilitytype
		) {
			window.dispatchEvent(new Event('resize'));
			dispatch(changeLeftsidebarViewType(leftSidebarViewType));
			dispatch(changeLeftsidebarSizeType(leftsidbarSizeType));
			dispatch(changeSidebarTheme(leftSidebarType));
			dispatch(changeLayoutMode(layoutModeType));
			dispatch(changeLayoutWidth(layoutWidthType));
			dispatch(changeLayoutPosition(layoutPositionType));
			dispatch(changeTopbarTheme(topbarThemeType));
			dispatch(changeLayout(layoutType));
			dispatch(changeSidebarImageType(leftSidebarImageType));
			dispatch(changeSidebarVisibility(sidebarVisibilitytype));
		}
	}, [layoutType,
		leftSidebarType,
		layoutModeType,
		layoutWidthType,
		layoutPositionType,
		topbarThemeType,
		leftsidbarSizeType,
		leftSidebarViewType,
		leftSidebarImageType,
		sidebarVisibilitytype,
		dispatch]);
	/*
	call dark/light mode
	*/
	const onChangeLayoutMode = (value) => {
		if (changeLayoutMode) {
			dispatch(changeLayoutMode(value));
		}
	};

	// class add remove in header 
	useEffect(() => {
		window.addEventListener("scroll", scrollNavigation, true);
	});

	function scrollNavigation() {
		var scrollup = document.documentElement.scrollTop;
		if (scrollup > 50) {
			setHeaderClass("topbar-shadow");
		} else {
			setHeaderClass("");
		}
	}

	return (
		<React.Fragment>
			<div id="layout-wrapper">
				<HeaderNoSideBar
					headerClass={headerClass}
					layoutModeType={layoutModeType}
					onChangeLayoutMode={onChangeLayoutMode} />
				<div className="main-content-no-sidebar">
					{props.children}
					<Footer />
				</div>
			</div>
			<RightSidebar />
		</React.Fragment>

	);
};

ClientsLayout.propTypes = {
	children: PropTypes.object,
};

export default ClientsLayout;